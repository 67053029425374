import { useEffect, useState } from 'react';
import { Img } from 'react-image';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import backgroundImage from '../../images/background-features.jpg';
import dashboard from '../../images/screenshots/board-report.webp';
import calculate from '../../images/screenshots/calculate-modal.webp';
import benchmark from '../../images/screenshots/main-dashboard-screenshot.webp';
import execsummary from '../../images/screenshots/exec-summ.webp';
import Container from "./Container";


const features = [
  {
    title: '18 x People and Talent benchmarks',
    description:
      "Benchmark your company's performance against startup industry standards and filter on heacount, stage and geography.",
    image: benchmark,
  },
  {
    title: 'Calculate your people metrics',
    description:
      "Enter your core people metrics and calculate your key HR and people-related data.",
    image: calculate,
  },
  {
    title: 'Visualize your data',
    description:
      "Visualize and organize the metrics that matter most to your people strategy.",
    image: dashboard,
  },
  {
    title: 'Generate beautiful charts',
    description:
      'Download reports for your board packs, tell a compelling story with your data and influence change.',
    image: execsummary,
  },
]

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState('horizontal')

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)')
    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }
    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)
    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-gradient-to-b from-blue-600 to-blue-700 pb-28 pt-20 sm:py-32"
    >
      <Img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%] opacity-30 blur-2xl"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
        data-unoptimized={true}
        priority=""
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            <span className="relative">
              <span className="relative z-10">Everything you need to benchmark your people metrics</span>
              <span className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-400 to-blue-200 transform scale-x-0 transition-transform duration-500 group-hover:scale-x-100"></span>
            </span>
          </h2>
          <p className="mt-6 text-lg tracking-tight text-blue-100">
            The benchmarking tool for startup and scale-up People leaders to tell a compelling story with data.
          </p>
        </div>
        
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-start gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-20 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <Tab
                      key={feature.title}
                      className={({ selected }) =>
                        clsx(
                          'group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6',
                          'cursor-pointer w-full text-left transition-all duration-300 ease-in-out',
                          selected
                            ? 'bg-white/10 lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                            : 'hover:bg-white/5',
                          'focus:outline-none'
                        )
                      }
                    >
                      {({ selected }) => (
                        <div className="relative z-10">
                          <h3
                            className={clsx(
                              'font-display text-lg',
                              selected ? 'text-white' : 'text-blue-100 group-hover:text-white'
                            )}
                          >
                            {feature.title}
                          </h3>
                          <p
                            className={clsx(
                              'mt-2 hidden text-sm lg:block',
                              selected ? 'text-blue-50' : 'text-blue-100 group-hover:text-blue-50'
                            )}
                          >
                            {feature.description}
                          </p>
                        </div>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel 
                    key={feature.title} 
                    unmount={false}
                    className="relative z-10 transition-all duration-500 transform"
                  >
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="relative mt-10 overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]
                      transform transition-all duration-500 hover:scale-[1.02]">
                      <Img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        priority=""
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                        data-unoptimized={true}
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  )
}
