import React from "react";
import Section from "../general/Section";
import { Link } from "../../util/router";


function Footer(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={props.sticky && "mt-auto"}
    >
      <footer className="container p-20">
        
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Company
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              <a
                href="https://www.openorg.fyi"
                className="font-medium text-gray-600 hover:text-gray-500"
              >
               Open Org Group Ltd
              </a>
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Free Tools
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                { url: "/tools/survey-generator", name: "Survey Question Generator" },
                // { url: "/tools/compensation-calculator", name: "Comp Band Calculator" },
                // { url: "/tools/turnover-cost", name: "Turnover Cost Calculator" },
                // { url: "/tools/dei-tracker", name: "DEI Goal Tracker" },
                // { url: "/tools/review-roi", name: "Performance Review ROI" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-600 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Product
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[

                { url: "/legal/terms-of-service", name: "Terms of Service" },
                { url: "/legal/privacy-policy", name: "Privacy Policy" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-600 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
         
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Socials
            </h4>
            <div className="mt-6 flex flex-row space-x-4">
              {[
                {
                  url: "https://www.linkedin.com/school/the-open-org/",
                  icon: (
                    <svg 
                      
                      viewBox="0 0 24 24"
                      className="w-6 h-6 text-gray-600" 
                    >
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                    </svg>
                  ),
                },
                // Add more social links as needed
              ].map((link, index) => (
                <a
                  href={link.url} // Include the correct URL with the protocol
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-blue-500"
                  key={index}
                >
                  {link.icon}
                </a>
              ))}


            </div>
          </div>
        </div>
      </footer>
    </Section>
  );
}

export default Footer;
