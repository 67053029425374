import React, { useState, Fragment } from 'react';
import Section from "../../components/general/Section";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, Transition, Tab } from '@headlessui/react';
import { questionBank, generateQuestions as generateQuestionsFromLogic, bestPractices } from './surveyGeneratorLogic';
import { 
  SparklesIcon, 
  ClockIcon, 
  CheckCircleIcon,
  ClipboardDocumentIcon,
  ArrowDownTrayIcon,
  AdjustmentsHorizontalIcon,
  XMarkIcon,
  DocumentDuplicateIcon,
  Cog6ToothIcon,
  ChartBarIcon,
  BookmarkIcon,
  StarIcon,
  ShareIcon,
  HeartIcon,
  UserGroupIcon,
  ChatBubbleLeftRightIcon,
  GlobeAltIcon,
  SlackIcon,
  LinkIcon,
  CheckIcon,
  ArrowRightIcon,
  ChartPieIcon,
  LightBulbIcon,
  BuildingOfficeIcon,
  RocketLaunchIcon,
  ChatBubbleBottomCenterTextIcon,
  UserMinusIcon,
  HomeIcon,
  ArrowsRightLeftIcon,
  UsersIcon,
  UserIcon,
  BanknotesIcon,
  DocumentTextIcon,
  CogIcon
} from '@heroicons/react/24/outline';
import { Toaster, toast } from 'react-hot-toast';

// Import only the logo we have
import transparentLogo from '../../images/transparent-logo.png';

// Import the question banks from surveyGeneratorLogic.js
import { 
  rapidGrowthQuestions, 
  remoteHybridQuestions, 
  mergerAcquisitionQuestions 
} from './surveyGeneratorLogic';

const surveyTemplates = [
  {
    id: 1,
    name: "Post-Layoff Pulse Check",
    description: "Gauge employee sentiment after a reduction in force or restructuring",
    icon: UserMinusIcon,
    questionCount: "10-12",
    focusAreas: ["leadership", "teamDynamics", "workLifeBalance"],
    recentEvents: ["layoffs", "restructuring"],
    questionCount: 12
  },
  {
    id: 2,
    name: "New Leadership Assessment",
    description: "Measure confidence in new leadership and understand concerns",
    icon: UserGroupIcon,
    questionCount: "8-10",
    focusAreas: ["leadership", "companyDirection", "careerGrowth"],
    recentEvents: ["leadershipChange"],
    questionCount: 10
  },
  {
    id: 3,
    name: "Rapid Growth Check-in",
    description: "Understand how employees are adapting to company scaling",
    icon: ChartBarIcon,
    questionCount: "10-12",
    focusAreas: ["workLifeBalance", "teamDynamics", "careerGrowth"],
    recentEvents: ["growth"],
    questionCount: 12
  },
  {
    id: 4,
    name: "Remote Work Assessment",
    description: "Assess effectiveness of remote work arrangements and policies",
    icon: HomeIcon,
    questionCount: "8-10",
    focusAreas: ["workLifeBalance", "teamDynamics", "manager"],
    recentEvents: ["remote"],
    questionCount: 10
  },
  {
    id: 5,
    name: "Post-Merger Integration",
    description: "Evaluate how well teams are integrating after a merger or acquisition",
    icon: ArrowsRightLeftIcon,
    questionCount: "10-12",
    focusAreas: ["leadership", "teamDynamics", "companyDirection"],
    recentEvents: ["merger"],
    questionCount: 12
  },
  {
    id: 6,
    name: "DEI Assessment",
    description: "Measure perceptions of diversity, equity, and inclusion efforts",
    icon: UsersIcon,
    questionCount: "8-10",
    focusAreas: ["dei", "leadership", "teamDynamics"],
    recentEvents: [],
    questionCount: 10
  },
  {
    id: 7,
    name: "Manager Effectiveness",
    description: "Gather feedback on manager performance and support",
    icon: UserIcon,
    questionCount: "8-10",
    focusAreas: ["manager", "careerGrowth", "teamDynamics"],
    recentEvents: [],
    questionCount: 10
  },
  {
    id: 8,
    name: "Compensation & Benefits Review",
    description: "Assess satisfaction with pay, benefits, and total rewards",
    icon: BanknotesIcon,
    questionCount: "6-8",
    focusAreas: ["compensation", "careerGrowth"],
    recentEvents: [],
    questionCount: 8
  },
  {
    id: "ways-of-working",
    name: "Ways of Working Refresh",
    description: "Comprehensive assessment of how your organization operates, makes decisions, and collaborates to identify improvement opportunities.",
    icon: CogIcon,
    questionCount: 20,
    focusAreas: ["decision making", "collaboration", "communication", "meetings", "processes"],
    recentEvents: ["organizational change", "growth"],
    category: "waysOfWorking",
    metadata: {
      template: "Ways of Working Refresh",
      focusAreas: ["Decision Making", "Collaboration", "Communication", "Meetings", "Processes", "Change Management"]
    }
  }
];

// Brand colors
const brandColors = {
  primary: 'from-blue-600 to-indigo-700',
  secondary: 'text-blue-600',
  accent: 'bg-blue-50',
  highlight: 'bg-indigo-50'
};

const shareOptions = [
  {
    id: 'slack',
    name: 'Share to Slack',
    icon: ChatBubbleLeftRightIcon,
    description: 'Share directly to your team channel',
    style: 'bg-[#4A154B] text-white'
  },
  {
    id: 'link',
    name: 'Copy Link',
    icon: LinkIcon,
    description: 'Get a shareable link',
    style: 'bg-gray-700 text-white'
  },
  {
    id: 'linkedin',
    name: 'Share on LinkedIn',
    icon: GlobeAltIcon,
    description: 'Share with your network',
    style: 'bg-[#0A66C2] text-white'
  }
];

// Question type display config
const questionTypeConfig = {
  agreement: { 
    icon: StarIcon,
    color: 'bg-blue-100 text-blue-800',
    label: 'Agreement Scale'
  },
  rating: {
    icon: ChartBarIcon,
    color: 'bg-purple-100 text-purple-800',
    label: 'Rating Scale'
  },
  openEnded: {
    icon: ChatBubbleBottomCenterTextIcon,
    color: 'bg-green-100 text-green-800',
    label: 'Open-Ended'
  },
  yesNo: {
    icon: CheckIcon,
    color: 'bg-amber-100 text-amber-800',
    label: 'Yes/No'
  },
  multiChoice: {
    icon: AdjustmentsHorizontalIcon,
    color: 'bg-rose-100 text-rose-800',
    label: 'Multiple Choice'
  }
};

export default function SurveyGenerator() {
  const [formData, setFormData] = useState({
    recentEvents: [],
    focusAreas: []
  });

  const [questions, setQuestions] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('questions');
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [settings, setSettings] = useState({
    anonymity: true,
    frequency: 'monthly',
    responseTime: '5-7',
    distribution: 'staggered'
  });
  const [savedTemplates, setSavedTemplates] = useState([]);
  const [userStats, setUserStats] = useState({
    surveysGenerated: 0,
    questionsCreated: 0,
    templatesShared: 0
  });
  const [showShareSuccess, setShowShareSuccess] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareableContent, setShareableContent] = useState(null);
  const [shareableLink, setShareableLink] = useState('');

  // Add metadata state
  const [metadata, setMetadata] = useState({
    template: null,
    focusAreas: []
  });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGenerating(true);
    
    // Ensure formData has all required fields for the enhanced generator
    const enhancedFormData = {
      ...formData,
      questionCount: formData.questionCount || 10,
      companyStage: formData.companyStage || 'growth'
    };
    
    // Generate questions using the enhanced logic from surveyGeneratorLogic
    setTimeout(() => {
      const generatedQuestions = generateQuestions(enhancedFormData);
      setQuestions(generatedQuestions);
      setGenerating(false);
    }, 2000);
  };

  const handleCopy = () => {
    if (!questions) return;
    const text = questions.map(q => q.text).join('\n\n');
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleReset = () => {
    setQuestions(null);
    setFormData({
      recentEvents: [],
      focusAreas: []
    });
  };

  const handleCopyQuestion = (text) => {
    navigator.clipboard.writeText(text);
    // Could add a small toast notification here
  };

  const handleExport = (format) => {
    if (!questions) return;
    
    const filename = `survey_questions.${format}`;
    const content = format === 'csv' 
      ? convertToCSV(questions)
      : JSON.stringify(questions, null, 2);
    
    const blob = new Blob([content], { 
      type: format === 'csv' ? 'text/csv;charset=utf-8' : 'application/json' 
    });
    
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Helper function to convert questions to CSV
  const convertToCSV = (questions) => {
    const headers = ['Question', 'Type', 'Category'];
    const rows = questions.map(q => [q.text, q.type, q.category]);
    return [headers, ...rows].map(row => row.join(',')).join('\n');
  };

  const handleTemplateSelect = (template) => {
    setShowTemplates(false);
    
    // Set the selected template
    setSelectedTemplate(template);
    
    // Update the form with template values
    setFormData({
      ...formData,
      focusAreas: template.focusAreas || [],
      recentEvents: template.recentEvents || [],
      questionCount: template.questionCount || 10
    });
    
    // Generate questions based on the template
    let generatedQuestions;
    
    if (template.id === "ways-of-working") {
      // Special handling for Ways of Working template
      generatedQuestions = generateWaysOfWorkingQuestions(template.questionCount);
    } else {
      // Standard question generation for other templates
      generatedQuestions = generateQuestions(template.focusAreas, template.recentEvents, template.questionCount);
    }
    
    // Update the questions state
    setQuestions(generatedQuestions);
    
    // Update metadata
    setMetadata({
      template: template.name,
      focusAreas: template.focusAreas?.map(area => 
        area.charAt(0).toUpperCase() + area.slice(1).replace(/([A-Z])/g, ' $1').trim()
      ) || []
    });
    
    toast.success(`${template.name} template loaded!`);
  };

  // Update the generateWaysOfWorkingQuestions function to ensure it returns enough questions
  const generateWaysOfWorkingQuestions = (count = 20) => {
    // Get all Ways of Working questions
    const baseQuestions = questionBank.waysOfWorking.base || [];
    const detailedQuestions = questionBank.waysOfWorking.detailed || [];
    
    // Combine all questions
    const allQuestions = [...baseQuestions, ...detailedQuestions];
    
    // Ensure we have enough questions
    if (allQuestions.length < count) {
      console.warn(`Only ${allQuestions.length} Ways of Working questions available, requested ${count}`);
    }
    
    // Shuffle all questions
    const shuffled = shuffle(allQuestions);
    
    // Take the requested number of questions, or all if we have fewer
    let selected = shuffled.slice(0, Math.min(count, shuffled.length));
    
    // Ensure we have a good mix of question types
    const types = ['agreement', 'openEnded', 'multiChoice', 'yesNo', 'rating'];
    types.forEach(type => {
      if (!selected.some(q => q.type === type)) {
        const typeQuestion = allQuestions.find(q => q.type === type);
        if (typeQuestion && selected.length > 0) {
          // Replace a random question to maintain count
          const randomIndex = Math.floor(Math.random() * selected.length);
          selected[randomIndex] = typeQuestion;
        }
      }
    });
    
    return selected;
  };

  // Fix the generateQuestions function to ensure it returns enough questions
  const generateQuestions = (formData) => {
    // Extract selected focus areas and recent events
    const { focusAreas = [], recentEvents = [], questionCount = 10 } = formData;
    
    // Try to use the imported function first
    try {
      const questionsFromLogic = generateQuestionsFromLogic({
        focusAreas,
        recentEvents,
        questionCount
      });
      
      // If we got enough questions, return them
      if (questionsFromLogic && questionsFromLogic.length >= Math.min(5, questionCount)) {
        console.log(`Using ${questionsFromLogic.length} questions from logic module`);
        return questionsFromLogic;
      }
    } catch (error) {
      console.warn("Error using imported generateQuestions function:", error);
      // Continue with our fallback implementation
    }
    
    // Fallback implementation if the imported function doesn't work well
    console.log("Using fallback question generation");
    
    // Get base questions from selected focus areas
    let questions = [];
    
    // Add base questions from each focus area
    focusAreas.forEach(area => {
      if (questionBank[area] && questionBank[area].base) {
        questions.push(...questionBank[area].base);
      }
    });
    
    // Add contextual questions based on recent events
    if (recentEvents && recentEvents.length > 0) {
      recentEvents.forEach(event => {
        // Add event-specific questions from each focus area
        focusAreas.forEach(area => {
          if (questionBank[area]?.contextual?.[event]) {
            questions.push(...questionBank[area].contextual[event]);
          }
        });
      });
    }
    
    // If we still don't have enough questions, add from other areas
    if (questions.length < questionCount) {
      const otherAreas = Object.keys(questionBank).filter(area => !focusAreas.includes(area));
      
      // Add questions from other areas until we have enough
      for (const area of otherAreas) {
        if (questions.length >= questionCount * 2) break; // Get 2x what we need for better shuffling
        
        if (questionBank[area] && questionBank[area].base) {
          questions.push(...questionBank[area].base);
        }
      }
    }
    
    // Shuffle all questions
    questions = shuffle(questions);
    
    // Prioritize recommended questions
    questions = questions.sort((a, b) => {
      if (a.recommended && !b.recommended) return -1;
      if (!a.recommended && b.recommended) return 1;
      return 0;
    });
    
    // Balance question types
    questions = balanceQuestionTypes(questions, questionCount);
    
    // Ensure we have at least one open-ended question
    const openEndedCount = questions.filter(q => q.type === 'openEnded').length;
    if (openEndedCount < 1) {
      const generalOpenEnded = {
        text: "What's one thing we could do to improve your experience at work?",
        type: "openEnded",
        category: "General Feedback"
      };
      questions.push(generalOpenEnded);
    }
    
    // Log how many questions we found
    console.log(`Generated ${questions.length} questions, returning ${Math.min(questionCount, questions.length)}`);
    
    // Limit to requested number of questions
    return questions.slice(0, Math.min(questionCount, questions.length));
  };

  // Helper function to shuffle an array
  const shuffle = (array) => {
    return [...array].sort(() => Math.random() - 0.5);
  };

  // Helper function to balance question types
  const balanceQuestionTypes = (questions, maxQuestions) => {
    const typeTargets = {
      agreement: 0.5,
      rating: 0.1,
      openEnded: 0.15,
      yesNo: 0.15,
      multiChoice: 0.1
    };

    // First, ensure we have at least one of each type if possible
    const types = Object.keys(typeTargets);
    types.forEach(type => {
      if (!questions.some(q => q.type === type)) {
        // Try to find a question of this type from the question bank
        for (const area of Object.values(questionBank)) {
          const typeQuestion = area.base?.find(q => q.type === type);
          if (typeQuestion) {
            questions.unshift(typeQuestion);
            break;
          }
        }
      }
    });

    // Then sort to balance according to targets
    return questions.sort((a, b) => {
      const aCurrentRatio = questions.filter(q => q.type === a.type).length / questions.length;
      const bCurrentRatio = questions.filter(q => q.type === b.type).length / questions.length;
      const aTarget = typeTargets[a.type] || 0;
      const bTarget = typeTargets[b.type] || 0;
      
      return Math.abs(aCurrentRatio - aTarget) - Math.abs(bCurrentRatio - bTarget);
    });
  };

  // Growth loop functions
  const handleSaveTemplate = (template) => {
    setSavedTemplates([...savedTemplates, template]);
    setUserStats(prev => ({
      ...prev,
      surveysGenerated: prev.surveysGenerated + 1
    }));
  };

  const generateShareableContent = (questions) => {
    const content = {
      questions,
      metadata: {
        generatedAt: new Date().toISOString(),
        source: 'peoplemetrics.fyi',
        template: selectedTemplate?.name || 'Custom Survey',
        focusAreas: formData.focusAreas
      }
    };
    return content;
  };

  const handleShare = async () => {
    try {
      // Prepare the data to be shared
      const shareData = {
        questions: questions,
        metadata: {
          template: selectedTemplate?.name || "Generated by peoplemetrics.fyi",
          focusAreas: formData.focusAreas,
          generatedAt: new Date().toISOString()
        }
      };

      // Convert to JSON string and encode to base64
      const jsonString = JSON.stringify(shareData);
      const base64 = btoa(jsonString)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, ''); // Remove padding

      // Generate the full URL
      const shareableLink = `${window.location.origin}/shared/${base64}`;
      console.log('Generated link:', shareableLink); // Debug log
      console.log('Share data:', shareData); // Debug log

      setShareableLink(shareableLink);
      setShowShareModal(true);
      
    } catch (error) {
      console.error('Share error:', error);
      toast.error('Error generating share link');
    }
  };

  // Get recommended question count based on company stage
  const getRecommendedCount = () => {
    return bestPractices[formData.companyStage]?.questionCount || "8-12";
  };

  const getQuestionTypeDistribution = (questions) => {
    return questions.reduce((acc, q) => {
      acc[q.type] = (acc[q.type] || 0) + 1;
      return acc;
    }, {});
  };

  const getFocusAreaDistribution = (questions) => {
    return questions.reduce((acc, q) => {
      acc[q.category] = (acc[q.category] || 0) + 1;
      return acc;
    }, {});
  };

  const renderQuestionIcon = (type) => {
    const IconComponent = questionTypeConfig[type].icon;
    return <IconComponent className="w-4 h-4 mr-1" />;
  };

  // Question card component
  const QuestionCard = ({ question, index }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.05 }}
      className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow"
    >
      <div className="p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-2 sm:gap-4">
          <div className="flex-1">
            <p className="text-gray-900 font-medium text-base sm:text-lg">
              {question.text}
            </p>
            <p className="text-xs sm:text-sm text-gray-500 mt-2 flex flex-wrap items-center gap-2">
              {question.category}
              {question.recommended && (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                  <CheckCircleIcon className="h-3 w-3 mr-1" />
                  Recommended
                </span>
              )}
            </p>
          </div>
          <div className="sm:ml-4 mt-2 sm:mt-0">
            {question.type && (
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${questionTypeConfig[question.type]?.color || 'bg-gray-100 text-gray-600'}`}>
                {questionTypeConfig[question.type]?.icon && renderQuestionIcon(question.type)}
                {questionTypeConfig[question.type]?.label || question.type}
              </span>
            )}
          </div>
        </div>
        
        {/* Display different inputs based on question type */}
        <div className="mt-4">
          {question.type === 'agreement' && (
            <div className="flex flex-col sm:flex-row justify-between items-center text-xs text-gray-500 gap-2">
              <span>Strongly Disagree</span>
              <div className="flex space-x-1">
                {[1, 2, 3, 4, 5].map(num => (
                  <div key={num} className="w-7 h-7 sm:w-8 sm:h-8 rounded-full border border-gray-300 flex items-center justify-center cursor-pointer hover:bg-gray-50">
                    {num}
                  </div>
                ))}
              </div>
              <span>Strongly Agree</span>
            </div>
          )}
          
          {question.type === 'rating' && (
            <div className="flex flex-col sm:flex-row justify-between items-center text-xs text-gray-500 gap-2">
              <span>Not at all likely</span>
              <div className="flex flex-wrap justify-center gap-1">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                  <div key={num} className="w-5 h-5 sm:w-6 sm:h-6 rounded-full border border-gray-300 flex items-center justify-center cursor-pointer hover:bg-gray-50 text-xs">
                    {num}
                  </div>
                ))}
              </div>
              <span>Extremely likely</span>
            </div>
          )}
          
          {question.type === 'openEnded' && (
            <div className="mt-2">
              <div className="border border-gray-300 rounded-md px-3 py-2 text-gray-400 bg-gray-50">
                Type your answer here...
              </div>
            </div>
          )}
          
          {question.type === 'yesNo' && (
            <div className="flex space-x-3 mt-2">
              <button className="flex-1 border border-gray-300 rounded-md py-2 hover:bg-gray-50">Yes</button>
              <button className="flex-1 border border-gray-300 rounded-md py-2 hover:bg-gray-50">No</button>
            </div>
          )}
          
          {question.type === 'multiChoice' && (
            <div className="space-y-2 mt-2">
              {(question.options || ['Option 1', 'Option 2', 'Option 3']).map((option, i) => (
                <div key={i} className="flex items-center">
                  <input
                    type="radio"
                    name={`question-${index}`}
                    className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <label className="ml-2 text-sm text-gray-700">{option}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );

  // Best practices component
  const BestPracticesPanel = () => (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mt-8">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Survey Best Practices</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div>
          <h4 className="text-sm font-medium text-gray-700">Recommended Length</h4>
          <p className="mt-1 text-sm text-gray-600">
            {getRecommendedCount()} questions
          </p>
        </div>
        <div>
          <h4 className="text-sm font-medium text-gray-700">Frequency</h4>
          <p className="mt-1 text-sm text-gray-600">
            {bestPractices[formData.companyStage]?.frequency || 'Monthly'}
          </p>
        </div>
        <div>
          <h4 className="text-sm font-medium text-gray-700">Response Time</h4>
          <p className="mt-1 text-sm text-gray-600">5-7 minutes</p>
        </div>
      </div>
    </div>
  );

  // Distribution chart component
  const DistributionChart = ({ data, title }) => (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mt-4">
      <h3 className="text-lg font-medium text-gray-900 mb-4">{title}</h3>
      <div className="space-y-3">
        {Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <div className="flex justify-between text-sm text-gray-600 mb-1">
              <span>{key}</span>
              <span>{value}</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 h-2 rounded-full"
                style={{ width: `${(value / Object.values(data).reduce((a, b) => a + b, 0)) * 100}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  if (generating) {
    return (
      <Section bgColor="bg-gray-50" className="min-h-screen">
        <div className="h-screen flex flex-col items-center justify-center">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            className="text-center"
          >
            <div className="mb-8">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full mx-auto"
              />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Generating Your Questions</h2>
            <p className="text-gray-600">Using research-backed templates to create your questions...</p>
          </motion.div>
        </div>
      </Section>
    );
  }

  if (questions) {
    return (
      <>
        <Toaster 
          position="top-right"
          toastOptions={{
            duration: 2000,
            style: {
              background: '#363636',
              color: '#fff',
            },
            success: {
              duration: 2000,
              iconTheme: {
                primary: '#4ade80',
                secondary: '#fff',
              },
            },
          }}
        />
        <Section bgColor="bg-gray-50" className="min-h-screen">
          {/* Results Hero Section */}
          <div className="relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-tr from-blue-600 via-indigo-600 to-indigo-700">
              <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                <div
                  className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                  style={{
                    clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                />
              </div>
            </div>

            <div className="relative max-w-7xl mx-auto px-4 py-12">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center"
              >
                <div className="flex flex-wrap justify-center gap-4 mb-8">
                  <motion.button
                    onClick={handleCopy}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="inline-flex items-center px-4 py-3 sm:px-6 rounded-xl bg-white/10 backdrop-blur-sm text-white hover:bg-white/20 transition-colors"
                  >
                    {copied ? (
                      <>
                        <CheckIcon className="w-5 h-5 mr-2" />
                        <span className="font-medium">Copied!</span>
                      </>
                    ) : (
                      <>
                        <ClipboardDocumentIcon className="w-5 h-5 mr-2" />
                        <span className="font-medium">Copy All</span>
                      </>
                    )}
                  </motion.button>
                  <motion.button
                    onClick={handleShare}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="inline-flex items-center px-4 py-3 sm:px-6 rounded-xl bg-white/10 backdrop-blur-sm text-white hover:bg-white/20 transition-colors"
                  >
                    <ShareIcon className="w-5 h-5 mr-2" />
                    <span className="font-medium">Share Link</span>
                  </motion.button>
                  <motion.button
                    onClick={handleReset}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="inline-flex items-center px-4 py-3 sm:px-6 rounded-xl bg-white text-blue-600 hover:bg-blue-50 transition-colors shadow-md"
                  >
                    <SparklesIcon className="w-5 h-5 mr-2" />
                    <span className="font-medium">Generate More</span>
                  </motion.button>
                </div>

                <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6">
                  Your Generated Questions
                </h1>
                <p className="text-xl text-blue-100 max-w-2xl mx-auto">
                  {questions.length} questions generated based on your inputs
                </p>
              </motion.div>
            </div>
          </div>

          {/* Questions Display */}
          <div className="max-w-7xl mx-auto px-4 py-12">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <div className="lg:col-span-2">
                {/* Questions List */}
                <div className="bg-white rounded-2xl shadow-xl">
                  {questions.map((question, index) => (
                    <QuestionCard key={index} question={question} index={index} />
                  ))}
                </div>
              </div>
              
              <div className="space-y-6">
                <BestPracticesPanel />
                <DistributionChart 
                  data={getQuestionTypeDistribution(questions)} 
                  title="Question Type Distribution" 
                />
                <DistributionChart 
                  data={getFocusAreaDistribution(questions)} 
                  title="Focus Area Distribution" 
                />
              </div>
            </div>
          </div>

          {/* Share Modal */}
          <Transition show={showShareModal} as={Fragment}>
            <Dialog onClose={() => setShowShareModal(false)} className="relative z-50">
              <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
              
              <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="mx-auto max-w-md w-full bg-white rounded-2xl shadow-lg">
                  <div className="p-6 border-b border-gray-200">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Share Questions
                      </Dialog.Title>
                      <button onClick={() => setShowShareModal(false)}>
                        <XMarkIcon className="w-5 h-5 text-gray-400" />
                      </button>
                    </div>
                  </div>

                  <div className="p-6">
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Shareable Link
                        </label>
                        <div className="flex gap-2">
                          <input
                            type="text"
                            readOnly
                            value={shareableLink}
                            className="flex-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(shareableLink);
                              toast.success('Link copied to clipboard!');
                            }}
                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </Dialog>
          </Transition>
        </Section>
      </>
    );
  }

  // Initial form view
  return (
    <>
      <Toaster 
        position="top-right"
        toastOptions={{
          duration: 2000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 2000,
            iconTheme: {
              primary: '#4ade80',
              secondary: '#fff',
            },
          },
        }}
      />
      <Section bgColor="bg-gradient-to-b from-blue-50 to-white">
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-tr from-blue-600 via-indigo-600 to-indigo-700">
            <div 
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>

          {/* Scientific/Data Animation Elements */}
          <div className="absolute inset-0 overflow-hidden">
            {/* Floating data points */}
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={`data-point-${i}`}
                className="absolute rounded-full bg-white/30 backdrop-blur-sm"
                style={{
                  width: Math.random() * 10 + 5,
                  height: Math.random() * 10 + 5,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ 
                  opacity: [0, 0.7, 0],
                  scale: [0, 1, 0],
                  y: [0, -30 - Math.random() * 50],
                  x: [0, (Math.random() - 0.5) * 30]
                }}
                transition={{
                  duration: 3 + Math.random() * 2,
                  repeat: Infinity,
                  delay: Math.random() * 5,
                  ease: "easeInOut"
                }}
              />
            ))}
            
            {/* DNA helix animation */}
            <div className="absolute right-[10%] top-[20%] h-[60%] w-[80px] opacity-30">
              {[...Array(10)].map((_, i) => (
                <React.Fragment key={`helix-${i}`}>
                  <motion.div
                    className="absolute w-4 h-4 bg-blue-200 rounded-full"
                    style={{ top: `${i * 10}%` }}
                    animate={{
                      x: [40, 0, 40],
                      opacity: [0.8, 1, 0.8]
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      delay: i * 0.3,
                      ease: "easeInOut"
                    }}
                  />
                  <motion.div
                    className="absolute w-4 h-4 bg-pink-200 rounded-full"
                    style={{ top: `${i * 10 + 5}%` }}
                    animate={{
                      x: [0, 40, 0],
                      opacity: [0.8, 1, 0.8]
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      delay: i * 0.3,
                      ease: "easeInOut"
                    }}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="relative max-w-6xl mx-auto px-4 py-24">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center"
            >
              <motion.div 
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ 
                  duration: 0.5,
                  delay: 0.2,
                  type: "spring",
                  stiffness: 100
                }}
                className="inline-flex items-center justify-center px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm mb-8 border border-white/20 shadow-lg"
              >
                <div className="flex items-center space-x-2 text-blue-50">
                  <span className="text-sm">Powered by</span>
                  <a 
                    href="https://peoplemetrics.fyi" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-white hover:text-blue-100 transition-colors font-medium"
                  >
                    <motion.img 
                      src={transparentLogo} 
                      alt="PeopleMetrics" 
                      className="h-5 mr-1" 
                      whileHover={{ rotate: 5, scale: 1.05 }}
                      transition={{ duration: 0.2 }}
                    />
                    <motion.span
                      whileHover={{ color: "#ffffff" }}
                    >
                      Peoplemetrics.fyi
                    </motion.span>
                  </a>
                </div>
              </motion.div>

              <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6 relative z-10">
                <motion.span
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  Survey 
                </motion.span>{" "}
                <motion.span
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                  className="relative"
                >
                  Question Generator
                </motion.span>
              </h1>
              <motion.p 
                className="text-xl text-blue-100 max-w-2xl mx-auto relative z-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
              >
                Generate effective questions for your next employee pulse or survey in seconds, based on research and best practices.
              </motion.p>
            </motion.div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-6xl mx-auto px-4 -mt-12 relative z-10 pb-24">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-2xl shadow-xl ring-1 ring-gray-900/10 overflow-hidden
              before:absolute before:inset-0 before:-z-10 before:rounded-xl before:bg-gradient-to-b before:from-indigo-500/5 before:to-blue-500/5 before:blur-2xl"
          >
            <div className="flex justify-between items-center mb-8">
              <div className="flex space-x-4">
                <motion.button
                  onClick={() => setShowTemplates(true)}
                  className="inline-flex items-center px-6 py-3 rounded-xl bg-white text-blue-600 hover:bg-blue-50 transition-colors shadow-lg shadow-blue-500/20"
                >
                  <DocumentDuplicateIcon className="w-5 h-5 mr-2" />
                  Template Gallery
                </motion.button>
                {questions && (
                  <button
                    onClick={() => handleSaveTemplate({ questions, formData })}
                    className="inline-flex items-center px-6 py-3 rounded-xl bg-blue-600 text-white hover:bg-blue-700 transition-colors shadow-lg shadow-blue-500/20"
                  >
                    <StarIcon className="w-5 h-5 mr-2" />
                    Save Questions
                  </button>
                )}
              </div>
            </div>

            {/* Remove grid layout and make form full width */}
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="bg-white rounded-2xl shadow-xl border border-gray-200/50 overflow-hidden backdrop-blur-sm"
            >
              <div className="p-8 border-b border-gray-200">
                <h2 className="text-2xl font-bold text-gray-900">Customize Your Questions</h2>
                <p className="mt-2 text-gray-600">
                  Tell us about your context to generate relevant questions
                </p>
              </div>

              <form onSubmit={handleSubmit} className="p-8 space-y-8">
                {/* Recent Events Section - Updated label with "Remote" */}
                <div className="space-y-4">
                  <h3 className="text-sm font-medium text-gray-700">Recent Events (Last 6 months)</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4">
                    {[
                      { id: 'layoffs', label: 'Layoffs/Restructuring', icon: BuildingOfficeIcon },
                      { id: 'leadership', label: 'Leadership Changes', icon: UserGroupIcon },
                      { id: 'growth', label: 'Rapid Growth', icon: RocketLaunchIcon },
                      { id: 'remote', label: 'Remote Work Adoption', icon: HomeIcon },
                      { id: 'merger', label: 'Merger/Acquisition', icon: BuildingOfficeIcon },
                      { id: 'strategy', label: 'Strategy Pivot', icon: ChartPieIcon }
                    ].map(({ id, label, icon: Icon }) => (
                      <label 
                        key={id} 
                        className={`relative flex items-start p-3 sm:p-4 rounded-xl transition-all duration-200 
                          ${formData.recentEvents.includes(id) 
                            ? 'bg-blue-50 border-2 border-blue-500 shadow-sm' 
                            : 'border-2 border-gray-200 hover:border-blue-400 hover:bg-gray-50'}`}
                      >
                        <div className="flex items-center h-5 flex-shrink-0">
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 focus:ring-offset-2"
                            checked={formData.recentEvents.includes(id)}
                            onChange={(e) => {
                              const events = e.target.checked 
                                ? [...formData.recentEvents, id]
                                : formData.recentEvents.filter(e => e !== id);
                              setFormData({...formData, recentEvents: events});
                            }}
                          />
                        </div>
                        <div className="ml-2 sm:ml-3 flex items-center min-w-0">
                          <Icon className="w-4 h-4 text-gray-500 mr-1 sm:mr-2 flex-shrink-0" />
                          <span className="text-xs sm:text-sm text-gray-700 truncate">{label}</span>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>

                {/* Focus Areas Section - Improved for mobile */}
                <div className="space-y-4">
                  <h3 className="text-sm font-medium text-gray-700">Focus Areas (select 2-3)</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 sm:gap-4">
                    {[
                      { id: 'leadership', label: 'Leadership Trust', icon: UserGroupIcon },
                      { id: 'growth', label: 'Career Growth', icon: RocketLaunchIcon },
                      { id: 'balance', label: 'Work-Life Balance', icon: HeartIcon },
                      { id: 'team', label: 'Team Dynamics', icon: ChatBubbleLeftRightIcon },
                      { id: 'direction', label: 'Company Direction', icon: ChartPieIcon },
                      { id: 'dei', label: 'DEI', icon: GlobeAltIcon },
                      { id: 'manager', label: 'Manager Effectiveness', icon: StarIcon },
                      { id: 'compensation', label: 'Compensation & Benefits', icon: BuildingOfficeIcon }
                    ].map(({ id, label, icon: Icon }) => (
                      <label 
                        key={id} 
                        className={`relative flex items-start p-3 sm:p-4 rounded-xl transition-all duration-200
                          ${formData.focusAreas.includes(id) 
                            ? 'bg-blue-50 border-2 border-blue-500 shadow-sm' 
                            : 'border-2 border-gray-200 hover:border-blue-400 hover:bg-gray-50'}`}
                      >
                        <div className="flex items-center h-5 flex-shrink-0">
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 focus:ring-offset-2"
                            checked={formData.focusAreas.includes(id)}
                            onChange={(e) => {
                              const areas = e.target.checked 
                                ? [...formData.focusAreas, id]
                                : formData.focusAreas.filter(a => a !== id);
                              setFormData({...formData, focusAreas: areas});
                            }}
                          />
                        </div>
                        <div className="ml-2 sm:ml-3 flex items-center min-w-0">
                          <Icon className="w-4 h-4 text-gray-500 mr-1 sm:mr-2 flex-shrink-0" />
                          <span className="text-xs sm:text-sm text-gray-700 truncate">{label}</span>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>

                {/* Question Count Section - Styled consistently with other selectors */}
                <div className="space-y-4">
                  <h3 className="text-sm font-medium text-gray-700">Number of Questions</h3>
                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 sm:gap-4">
                    {[5, 10, 15, 20].map(count => (
                      <label
                        key={count}
                        className={`relative flex items-center p-3 sm:p-4 rounded-xl transition-all duration-200 cursor-pointer
                          ${formData.questionCount === count 
                            ? 'bg-blue-50 border-2 border-blue-500 shadow-sm' 
                            : 'border-2 border-gray-200 hover:border-blue-400 hover:bg-gray-50'}`}
                      >
                        <div className="flex items-center h-5 mr-2 sm:mr-3 flex-shrink-0">
                          <input
                            type="radio"
                            name="questionCount"
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500 focus:ring-offset-2"
                            checked={formData.questionCount === count}
                            onChange={() => setFormData({...formData, questionCount: count})}
                          />
                        </div>
                        <div className="flex items-center justify-center flex-1">
                          <span className="text-xs sm:text-sm font-medium text-gray-700">{count} Questions</span>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>

                {/* Submit Button with Gradient */}
                <div className="pt-4">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    className="w-full flex justify-center items-center px-4 py-3 sm:py-4 border border-transparent rounded-xl shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-600 via-indigo-600 to-indigo-700 hover:from-blue-700 hover:via-indigo-700 hover:to-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
                  >
                    <SparklesIcon className="w-5 h-5 mr-2" />
                    Generate Questions
                  </motion.button>
                </div>
              </form>
            </motion.div>

            {/* Results Panel */}
            <AnimatePresence mode="wait">
              {questions ? (
                <motion.div
                  key="results"
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  className="bg-white rounded-2xl shadow-xl border border-gray-200/50 overflow-hidden backdrop-blur-sm"
                >
                  <div className="p-8 border-b border-gray-200">
                    <div className="flex justify-between items-center">
                      <div>
                        <h2 className="text-2xl font-bold text-gray-900">Generated Questions</h2>
                        <p className="mt-2 text-gray-600">
                          {questions.length} questions tailored to your context
                        </p>
                      </div>
                      <div className="flex space-x-4">
                        <button
                          onClick={handleCopy}
                          className="inline-flex items-center px-6 py-3 rounded-xl bg-white text-blue-600 hover:bg-blue-50 transition-colors shadow-sm border border-blue-100"
                        >
                          {copied ? (
                            <>
                              <CheckIcon className="w-5 h-5 mr-2" />
                              Copied!
                            </>
                          ) : (
                            <>
                              <ClipboardDocumentIcon className="w-5 h-5 mr-2" />
                              Copy All
                            </>
                          )}
                        </button>
                        <button
                          onClick={() => {
                            setShareableContent(generateShareableContent(questions));
                            setShowShareModal(true);
                          }}
                          className="inline-flex items-center px-6 py-3 rounded-xl bg-blue-600 text-white hover:bg-blue-700 transition-colors shadow-lg shadow-blue-500/20"
                        >
                          <ShareIcon className="w-5 h-5 mr-2" />
                          Share Questions
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Questions List */}
                  <div className="divide-y divide-gray-200">
                    {questions.map((question, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1 }}
                        className="p-8 hover:bg-gray-50/50 transition-colors"
                      >
                        <div className="flex justify-between items-start gap-8">
                          <div className="flex-1">
                            <p className="text-lg text-gray-900 font-medium leading-relaxed">
                              {question.text}
                            </p>
                            <div className="flex items-center gap-4 mt-3">
                              <span className={`px-3 py-1 rounded-full text-sm font-medium bg-${questionTypeConfig[question.type].color} flex items-center`}>
                                {renderQuestionIcon(question.type)}
                                {question.type}
                              </span>
                              <span className="text-sm text-gray-500">{question.category}</span>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>

                  {/* Best Practices Footer */}
                  <div className="p-8 bg-gray-50 border-t border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-900 mb-4">Question Best Practices</h3>
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <p className="text-sm font-medium text-gray-900">Recommended Frequency</p>
                        <p className="mt-1 text-sm text-gray-600">Run these questions every 3-6 months</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-900">Response Time</p>
                        <p className="mt-1 text-sm text-gray-600">5-7 minutes for all questions</p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>

            {/* Simplified Powered by section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="mt-16 pb-8 text-center"
            >
              <div className="flex items-center justify-center space-x-2 text-gray-500">
                <span>Powered by</span>
                <a 
                  href="https://peoplemetrics.fyi" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors font-medium"
                >
                  <img 
                    src={transparentLogo} 
                    alt="PeopleMetrics" 
                    className="h-5 mr-1" 
                  />
                  Peoplemetrics.fyi
                </a>
              </div>
              <p className="mt-2 text-sm text-gray-400">
                Flex your People team's data muscles with{" "}
                <a 
                  href="https://peoplemetrics.fyi" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700 transition-colors"
                >
                  peoplemetrics.fyi
                </a>
              </p>
            </motion.div>
          </motion.div>
        </div>

        {/* Templates Modal - Improved for mobile */}
        <Transition show={showTemplates} as={Fragment}>
          <Dialog onClose={() => setShowTemplates(false)} className="relative z-50">
            <div className="fixed inset-0 bg-gray-900/75 backdrop-blur-sm transition-opacity" aria-hidden="true" />
            
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 sm:p-6">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white shadow-xl transition-all w-full max-w-3xl max-h-[85vh] flex flex-col">
                    <div className="flex items-center justify-between p-4 sm:p-6 border-b border-gray-200">
                      <Dialog.Title className="text-lg sm:text-xl font-medium text-gray-900">
                        Survey Templates
                      </Dialog.Title>
                      <button
                        onClick={() => setShowTemplates(false)}
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    
                    <div className="overflow-y-auto p-4 sm:p-6 flex-grow">
                      <p className="text-gray-600 mb-6">
                        Choose a template to quickly generate relevant questions for your specific context.
                      </p>
                      
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {surveyTemplates.map((template) => (
                          <motion.div
                            key={template.id}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="relative rounded-xl border border-gray-200 bg-white p-4 sm:p-5 shadow-sm hover:shadow-md transition-all cursor-pointer"
                            onClick={() => handleTemplateSelect(template)}
                          >
                            <div className="flex items-start gap-3 sm:gap-4">
                              <div className="rounded-lg bg-blue-50 p-2 sm:p-3 text-blue-600 flex-shrink-0">
                                <template.icon className="h-5 w-5 sm:h-6 sm:w-6" />
                              </div>
                              <div className="flex-1 min-w-0">
                                <h3 className="text-sm sm:text-base font-medium text-gray-900 mb-1">
                                  {template.name}
                                </h3>
                                <p className="text-xs sm:text-sm text-gray-500 line-clamp-2">
                                  {template.description}
                                </p>
                                <div className="mt-2 flex items-center text-xs text-gray-500">
                                  <DocumentTextIcon className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                                  <span>{template.questionCount} questions</span>
                                </div>
                              </div>
                            </div>
                            
                            <div className="mt-3 pt-3 border-t border-gray-100">
                              <div className="flex flex-wrap gap-1 sm:gap-2">
                                {template.focusAreas.map((area) => (
                                  <span 
                                    key={area} 
                                    className="inline-flex items-center rounded-full bg-blue-50 px-1.5 py-0.5 sm:px-2 sm:py-1 text-xs font-medium text-blue-700"
                                  >
                                    {area.charAt(0).toUpperCase() + area.slice(1)}
                                  </span>
                                ))}
                                {template.recentEvents && template.recentEvents.length > 0 && template.recentEvents.map((event) => (
                                  <span 
                                    key={event} 
                                    className="inline-flex items-center rounded-full bg-amber-50 px-1.5 py-0.5 sm:px-2 sm:py-1 text-xs font-medium text-amber-700"
                                  >
                                    {event.charAt(0).toUpperCase() + event.slice(1)}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* Settings Modal */}
        <Transition show={showSettings} as={Fragment}>
          <Dialog onClose={() => setShowSettings(false)} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel className="mx-auto max-w-2xl w-full bg-white rounded-xl shadow-lg">
                <div className="p-6 border-b border-gray-200">
                  <div className="flex items-center justify-between">
                    <Dialog.Title className="text-lg font-medium text-gray-900">
                      Survey Settings
                    </Dialog.Title>
                    <button onClick={() => setShowSettings(false)}>
                      <XMarkIcon className="w-5 h-5 text-gray-400" />
                    </button>
                  </div>
                </div>

                <div className="p-6">
                  <div className="space-y-6">
                    {/* Anonymity Setting */}
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">Response Anonymity</h3>
                      <div className="mt-2 space-y-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            checked={settings.anonymity}
                            onChange={() => setSettings({...settings, anonymity: true})}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                          />
                          <span className="ml-3">
                            <span className="text-sm font-medium text-gray-900">Anonymous</span>
                            <span className="text-sm text-gray-500 block">Recommended for honest feedback</span>
                          </span>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            checked={!settings.anonymity}
                            onChange={() => setSettings({...settings, anonymity: false})}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                          />
                          <span className="ml-3">
                            <span className="text-sm font-medium text-gray-900">Named Responses</span>
                            <span className="text-sm text-gray-500 block">Better for direct follow-up</span>
                          </span>
                        </label>
                      </div>
                    </div>

                    {/* Frequency Setting */}
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">Survey Frequency</h3>
                      <select
                        value={settings.frequency}
                        onChange={(e) => setSettings({...settings, frequency: e.target.value})}
                        className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="weekly">Weekly</option>
                        <option value="biweekly">Bi-weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                      </select>
                    </div>

                    {/* Distribution Setting */}
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">Distribution Method</h3>
                      <select
                        value={settings.distribution}
                        onChange={(e) => setSettings({...settings, distribution: e.target.value})}
                        className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="all">All at once</option>
                        <option value="staggered">Staggered by team</option>
                        <option value="random">Random sampling</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="p-6 bg-gray-50 border-t border-gray-200">
                  <div className="flex justify-end">
                    <button
                      onClick={() => setShowSettings(false)}
                      className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                    >
                      Save Settings
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </Transition>
      </Section>
    </>
  );
}

// Helper function to generate shareable link
async function generateShareableLink(template) {
  // Implementation would depend on your backend
  return `https://yourapp.com/templates/${btoa(JSON.stringify(template))}`;
} 