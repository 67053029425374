import React from 'react';
import Section from "../../components/general/Section";

export default function ReviewRoi() {
  return (
    <Section
      bgColor="bg-white"
      className="min-h-screen"
    >
      <div className="max-w-6xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4 text-center">
          Performance Review ROI Calculator
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto text-center mb-16">
          Coming soon - Calculate the ROI of your review process
        </p>
      </div>
    </Section>
  );
} 