// Question bank organized by focus areas and context
// Question bank organized by focus areas and context
export const questionBank = {
  leadership: {
    base: [
      {
        text: "I have confidence in our leadership team's ability to execute our vision",
        type: "agreement",
        category: "Leadership Trust",
        recommended: true
      },
      {
        text: "Leadership effectively balances speed and thoughtfulness in decision-making",
        type: "agreement",
        category: "Decision Making",
        recommended: true
      },
      {
        text: "Our leadership team demonstrates transparency about company challenges and opportunities",
        type: "agreement",
        category: "Communication"
      },
      {
        text: "I understand how my work contributes to our company's mission",
        type: "agreement",
        category: "Strategic Alignment",
        recommended: true
      },
      {
        text: "Leadership effectively prioritizes between growth and operational stability",
        type: "agreement",
        category: "Strategic Balance"
      }
    ],
    contextual: {
      layoffs: [
        {
          text: "I trust that leadership made the right decisions in the recent restructuring",
          type: "agreement",
          category: "Leadership Trust"
        },
        {
          text: "Leadership was transparent in communicating the reasons for the layoffs/restructuring",
          type: "agreement",
          category: "Communication"
        },
        {
          text: "What could leadership have done differently to maintain trust during this transition?",
          type: "openEnded",
          category: "Leadership Trust"
        },
        {
          text: "I feel leaders have a clear plan for the company moving forward",
          type: "yesNo",
          category: "Vision"
        },
        {
          text: "I feel confident in the company's strategy post-restructuring",
          type: "agreement",
          category: "Strategic Direction"
        },
        {
          text: "Leadership has clearly explained the vision moving forward",
          type: "agreement",
          category: "Communication"
        },
        {
          text: "What's still unclear about where the company is headed?",
          type: "openEnded",
          category: "Strategic Direction"
        }
      ],
      restructuring: [
        {
          text: "I understand the strategic rationale behind recent organizational changes",
          type: "agreement",
          category: "Change Management"
        },
        {
          text: "Leadership has provided clear direction about our path forward",
          type: "agreement",
          category: "Vision"
        },
        {
          text: "What additional information would help you feel more confident about our company's direction?",
          type: "openEnded",
          category: "Communication"
        }
      ],
      leadershipChange: [
        {
          text: "I am confident in the new leadership team",
          type: "agreement",
          category: "Leadership Trust"
        },
        {
          text: "The new leadership has communicated their vision effectively",
          type: "agreement",
          category: "Communication"
        },
        {
          text: "What's one thing new leadership could do to earn your trust faster?",
          type: "openEnded",
          category: "Leadership Trust"
        },
        {
          text: "I understand the new leadership's strategic priorities",
          type: "yesNo",
          category: "Strategic Direction"
        },
        {
          text: "Leadership has clearly explained their vision for the company",
          type: "agreement",
          category: "Communication"
        },
        {
          text: "What's one concern you have about the company's new direction?",
          type: "openEnded",
          category: "Strategic Direction"
        }
      ],
      growth: [
        {
          text: "Leadership is effectively managing the company's rapid growth",
          type: "agreement",
          category: "Growth Management"
        },
        {
          text: "Leadership is transparent about the challenges of scaling",
          type: "agreement",
          category: "Communication"
        },
        {
          text: "What's one thing leadership could do better during this phase of growth?",
          type: "openEnded",
          category: "Leadership Improvement"
        },
        {
          text: "I believe the company is scaling in the right way",
          type: "yesNo",
          category: "Strategic Direction"
        },
        {
          text: "I am confident that leadership has a solid plan for sustainable growth",
          type: "agreement",
          category: "Strategic Planning"
        },
        {
          text: "What's one aspect of our scaling strategy that could be clearer?",
          type: "openEnded",
          category: "Strategic Clarity"
        }
      ],
      remote: [
        {
          text: "I am confident in leadership's approach to remote work",
          type: "agreement",
          category: "Remote Strategy"
        },
        {
          text: "Leadership has been clear in communicating remote policies",
          type: "yesNo",
          category: "Communication"
        },
        {
          text: "What's one thing leadership could improve about the remote work transition?",
          type: "openEnded",
          category: "Remote Improvement"
        },
        {
          text: "I believe the company is handling the remote transition well",
          type: "yesNo",
          category: "Change Management"
        },
        {
          text: "Leadership has provided clarity on how remote work aligns with business strategy",
          type: "yesNo",
          category: "Strategic Alignment"
        }
      ],
      merger: [
        {
          text: "I am confident in leadership's handling of the merger/acquisition",
          type: "agreement",
          category: "Change Management"
        },
        {
          text: "Leadership has been transparent about the impact of this merger/acquisition",
          type: "yesNo",
          category: "Communication"
        },
        {
          text: "What's one thing leadership could do to build more trust during this transition?",
          type: "openEnded",
          category: "Trust Building"
        },
        {
          text: "I feel aligned with the new company direction post-merger",
          type: "yesNo",
          category: "Strategic Alignment"
        },
        {
          text: "Leadership has clearly explained the long-term vision following the merger/acquisition",
          type: "agreement",
          category: "Vision"
        },
        {
          text: "What's one concern you have about the company's direction post-merger?",
          type: "openEnded",
          category: "Strategic Concerns"
        }
      ],
      fundraising: [
        {
          text: "I understand how our recent funding will impact our strategy",
          type: "agreement",
          category: "Strategic Clarity"
        },
        {
          text: "Our growth targets feel achievable",
          type: "agreement",
          category: "Goals"
        }
      ]
    }
  },
  
  teamDynamics: {
    base: [
      {
        text: "My team collaborates effectively",
        type: "agreement",
        category: "Collaboration",
        recommended: true
      },
      {
        text: "I feel psychologically safe to share ideas and concerns with my team",
        type: "agreement",
        category: "Psychological Safety",
        recommended: true
      },
      {
        text: "My team has the resources needed to succeed",
        type: "agreement",
        category: "Resources"
      }
    ],
    contextual: {
      layoffs: [
        {
          text: "My team's ability to collaborate has changed since the restructuring",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "Collaboration"
        },
        {
          text: "My team has the support needed to succeed post-restructuring",
          type: "yesNo",
          category: "Resources"
        },
        {
          text: "What's one challenge your team is facing right now?",
          type: "openEnded",
          category: "Team Challenges"
        }
      ],
      leadershipChange: [
        {
          text: "Team dynamics have changed under new leadership",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "Team Dynamics"
        },
        {
          text: "Leadership is fostering collaboration across teams",
          type: "yesNo",
          category: "Collaboration"
        },
        {
          text: "What's one challenge your team is facing under new leadership?",
          type: "openEnded",
          category: "Team Challenges"
        }
      ],
      growth: [
        {
          text: "Rapid growth has affected team collaboration",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "Collaboration"
        },
        {
          text: "I feel well connected to new team members as the company scales",
          type: "yesNo",
          category: "Team Connection"
        },
        {
          text: "What's one challenge your team is facing due to rapid growth?",
          type: "openEnded",
          category: "Growth Challenges"
        }
      ],
      remote: [
        {
          text: "I feel connected to my team in our remote setup",
          type: "agreement",
          category: "Team Connection"
        },
        {
          text: "I have enough opportunities for informal interactions with colleagues",
          type: "yesNo",
          category: "Social Connection"
        },
        {
          text: "What's one way team collaboration could be improved in our remote model?",
          type: "openEnded",
          category: "Collaboration Improvement"
        }
      ],
      merger: [
        {
          text: "Teams from both companies are integrating well",
          type: "agreement",
          category: "Integration"
        },
        {
          text: "Collaboration has changed due to the merger",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "Collaboration"
        },
        {
          text: "What's one challenge your team is facing in this transition?",
          type: "openEnded",
          category: "Integration Challenges"
        }
      ],
      hybrid: [
        {
          text: "Our hybrid work model creates an equitable experience for all team members",
          type: "agreement",
          category: "Equity"
        },
        {
          text: "I feel equally included in meetings regardless of my location",
          type: "agreement",
          category: "Inclusion"
        }
      ]
    }
  },
  
  careerGrowth: {
    base: [
      {
        text: "I have clear opportunities for career growth here",
        type: "agreement",
        category: "Career Path",
        recommended: true
      },
      {
        text: "I receive meaningful feedback that helps me improve",
        type: "agreement",
        category: "Feedback",
        recommended: true
      },
      {
        text: "I have access to the learning resources I need to grow",
        type: "agreement",
        category: "Learning"
      }
    ],
    contextual: {
      layoffs: [
        {
          text: "I see a path for my career growth in the company post-restructuring",
          type: "yesNo",
          category: "Career Path"
        },
        {
          text: "My career development opportunities have changed as a result of this transition",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "Development"
        },
        {
          text: "What support would help you feel more confident in your future at the company?",
          type: "openEnded",
          category: "Support Needs"
        }
      ],
      leadershipChange: [
        {
          text: "I believe the new leadership values career growth for employees",
          type: "yesNo",
          category: "Leadership Values"
        },
        {
          text: "Leadership has outlined a clear career development strategy",
          type: "yesNo",
          category: "Development Strategy"
        },
        {
          text: "What's one thing leadership could do to help you grow in your role?",
          type: "openEnded",
          category: "Growth Support"
        }
      ],
      growth: [
        {
          text: "There are more career opportunities due to the company's growth",
          type: "yesNo",
          category: "Opportunities"
        },
        {
          text: "Leadership has provided a clear roadmap for career progression in our fast-growing environment",
          type: "yesNo",
          category: "Career Roadmap"
        },
        {
          text: "What's one way the company could better support your career development right now?",
          type: "openEnded",
          category: "Development Support"
        }
      ],
      remote: [
        {
          text: "Remote work has impacted my career growth opportunities",
          type: "multiChoice",
          options: ["Positively", "No change", "Negatively"],
          category: "Remote Impact"
        },
        {
          text: "Leadership provides sufficient career development resources in our remote environment",
          type: "yesNo",
          category: "Development Resources"
        },
        {
          text: "What's one thing that could improve career growth in our remote setup?",
          type: "openEnded",
          category: "Remote Growth"
        }
      ],
      merger: [
        {
          text: "I see new career opportunities arising from this merger/acquisition",
          type: "yesNo",
          category: "Opportunities"
        },
        {
          text: "I have received clarity on how this merger will impact career growth",
          type: "yesNo",
          category: "Career Clarity"
        },
        {
          text: "What's one thing leadership could do to support career development through this transition?",
          type: "openEnded",
          category: "Transition Support"
        }
      ]
    }
  },
  
  workLifeBalance: {
    base: [
      {
        text: "I am able to maintain a healthy work-life balance",
        type: "agreement",
        category: "Balance",
        recommended: true
      },
      {
        text: "My workload is sustainable",
        type: "agreement",
        category: "Workload",
        recommended: true
      },
      {
        text: "I feel comfortable taking time off when needed",
        type: "agreement",
        category: "Time Off"
      }
    ],
    contextual: {
      layoffs: [
        {
          text: "My responsibilities have increased as a result of recent layoffs",
          type: "yesNo",
          category: "Workload"
        },
        {
          text: "My current workload post-restructuring is sustainable",
          type: "agreement",
          category: "Sustainability"
        },
        {
          text: "What's one thing leadership could do to help you manage your workload better?",
          type: "openEnded",
          category: "Workload Management"
        }
      ],
      leadershipChange: [
        {
          text: "There have been changes to workload expectations under new leadership",
          type: "yesNo",
          category: "Expectations"
        },
        {
          text: "New leadership recognizes the importance of work-life balance",
          type: "agreement",
          category: "Leadership Values"
        },
        {
          text: "What's one change leadership could make to improve work-life balance?",
          type: "openEnded",
          category: "Balance Improvement"
        }
      ],
      growth: [
        {
          text: "Rapid growth has impacted my workload",
          type: "multiChoice",
          options: ["Increased", "Stayed the same", "Decreased"],
          category: "Workload Impact"
        },
        {
          text: "The company is hiring fast enough to support current workloads",
          type: "yesNo",
          category: "Hiring Pace"
        },
        {
          text: "What's one thing leadership could do to improve workload management during growth?",
          type: "openEnded",
          category: "Growth Management"
        }
      ],
      remote: [
        {
          text: "Remote work has affected my work-life balance",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "Remote Impact"
        },
        {
          text: "I feel pressure to be 'always on' in our remote setting",
          type: "yesNo",
          category: "Boundaries"
        },
        {
          text: "What's one change that could improve your work-life balance in our current setup?",
          type: "openEnded",
          category: "Balance Improvement"
        }
      ],
      merger: [
        {
          text: "The merger/acquisition has affected my workload",
          type: "multiChoice",
          options: ["Increased", "Stayed the same", "Decreased"],
          category: "Workload Impact"
        },
        {
          text: "I feel supported in maintaining work-life balance during this transition",
          type: "yesNo",
          category: "Transition Support"
        },
        {
          text: "What's one thing that would help you manage workload better right now?",
          type: "openEnded",
          category: "Workload Management"
        }
      ]
    }
  },
  
  companyDirection: {
    base: [
      {
        text: "I understand our company's strategic priorities",
        type: "agreement",
        category: "Strategic Clarity",
        recommended: true
      },
      {
        text: "I believe our company is headed in the right direction",
        type: "agreement",
        category: "Direction",
        recommended: true
      },
      {
        text: "I understand how my work contributes to our company goals",
        type: "agreement",
        category: "Alignment"
      }
    ],
    contextual: {
      layoffs: [
        {
          text: "I feel confident in the company's strategy post-restructuring",
          type: "agreement",
          category: "Confidence"
        },
        {
          text: "Leadership has clearly explained the vision moving forward",
          type: "agreement",
          category: "Vision Communication"
        },
        {
          text: "What's still unclear about where the company is headed?",
          type: "openEnded",
          category: "Clarity Needs"
        }
      ],
      leadershipChange: [
        {
          text: "I understand the new leadership's strategic priorities",
          type: "yesNo",
          category: "Strategic Understanding"
        },
        {
          text: "Leadership has clearly explained their vision for the company",
          type: "agreement",
          category: "Vision Communication"
        },
        {
          text: "What's one concern you have about the company's new direction?",
          type: "openEnded",
          category: "Direction Concerns"
        }
      ],
      growth: [
        {
          text: "I believe the company is scaling in the right way",
          type: "yesNo",
          category: "Scaling Strategy"
        },
        {
          text: "I am confident that leadership has a solid plan for sustainable growth",
          type: "agreement",
          category: "Growth Planning"
        },
        {
          text: "What's one aspect of our scaling strategy that could be clearer?",
          type: "openEnded",
          category: "Strategy Clarity"
        }
      ],
      remote: [
        {
          text: "I believe the company is handling the remote transition well",
          type: "yesNo",
          category: "Transition Management"
        },
        {
          text: "Leadership has provided clarity on how remote work aligns with business strategy",
          type: "yesNo",
          category: "Strategic Alignment"
        },
        {
          text: "What concerns, if any, do you have about our remote transition?",
          type: "openEnded",
          category: "Transition Concerns"
        }
      ],
      merger: [
        {
          text: "I feel aligned with the new company direction post-merger",
          type: "yesNo",
          category: "Direction Alignment"
        },
        {
          text: "Leadership has clearly explained the long-term vision",
          type: "agreement",
          category: "Vision Communication"
        },
        {
          text: "What's one concern you have about the company's direction post-merger?",
          type: "openEnded",
          category: "Direction Concerns"
        }
      ]
    }
  },
  
  dei: {
    base: [
      {
        text: "Our company fosters an inclusive environment",
        type: "agreement",
        category: "Inclusion",
        recommended: true
      },
      {
        text: "I feel I can bring my authentic self to work",
        type: "agreement",
        category: "Belonging",
        recommended: true
      },
      {
        text: "Our company values diverse perspectives",
        type: "agreement",
        category: "Diversity"
      }
    ],
    contextual: {
      layoffs: [
        {
          text: "Layoffs were conducted in a fair and unbiased manner",
          type: "yesNo",
          category: "Fairness"
        },
        {
          text: "The company's commitment to DEI has been impacted by recent changes",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "DEI Impact"
        },
        {
          text: "What concerns, if any, do you have about DEI efforts post-restructuring?",
          type: "openEnded",
          category: "DEI Concerns"
        }
      ],
      leadershipChange: [
        {
          text: "I believe new leadership prioritizes DEI",
          type: "yesNo",
          category: "Leadership Priority"
        },
        {
          text: "I have noticed changes in DEI efforts since the leadership transition",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "DEI Changes"
        },
        {
          text: "What's one action new leadership should take to strengthen DEI?",
          type: "openEnded",
          category: "DEI Action"
        }
      ],
      growth: [
        {
          text: "DEI is being prioritized as the company scales",
          type: "yesNo",
          category: "DEI Priority"
        },
        {
          text: "Diverse perspectives are being included in hiring and decision-making during this growth phase",
          type: "yesNo",
          category: "Inclusive Practices"
        },
        {
          text: "What's one thing the company could do to maintain DEI in a period of rapid growth?",
          type: "openEnded",
          category: "DEI Maintenance"
        }
      ],
      remote: [
        {
          text: "Remote policies are equitable for all employees",
          type: "yesNo",
          category: "Policy Equity"
        },
        {
          text: "Remote changes have impacted workplace inclusivity",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "Inclusivity Impact"
        },
        {
          text: "What's one DEI consideration leadership should focus on in our remote model?",
          type: "openEnded",
          category: "DEI Focus"
        }
      ],
      merger: [
        {
          text: "DEI values are being maintained during the merger",
          type: "yesNo",
          category: "Values Maintenance"
        },
        {
          text: "There have been noticeable changes in DEI efforts since the merger began",
          type: "multiChoice",
          options: ["Improved", "Stayed the same", "Worsened"],
          category: "DEI Changes"
        },
        {
          text: "What's one thing leadership should prioritize for DEI post-merger?",
          type: "openEnded",
          category: "DEI Priority"
        }
      ]
    }
  },
  
  managerEffectiveness: {
    base: [
      {
        text: "My manager provides actionable feedback on my work",
        type: "agreement",
        category: "Feedback",
        recommended: true
      },
      {
        text: "My manager helps me understand how my work impacts company goals",
        type: "agreement",
        category: "Alignment"
      },
      {
        text: "I have regular 1:1s with my manager",
        type: "agreement",
        category: "Communication"
      },
      {
        text: "My manager supports my professional development",
        type: "agreement",
        category: "Growth",
        recommended: true
      }
    ],
    contextual: {
      layoffs: [
        {
          text: "I have felt supported by my manager during this transition",
          type: "agreement",
          category: "Transition Support"
        },
        {
          text: "My manager has the right resources to support the team through this change",
          type: "yesNo",
          category: "Manager Resources"
        },
        {
          text: "What's one thing your manager has done well during this transition?",
          type: "openEnded",
          category: "Manager Strengths"
        }
      ],
      leadershipChange: [
        {
          text: "My manager is aligned with new leadership",
          type: "yesNo",
          category: "Alignment"
        },
        {
          text: "New leadership has provided managers with the tools they need to succeed",
          type: "yesNo",
          category: "Manager Support"
        },
        {
          text: "What's one way your manager could better support you right now?",
          type: "openEnded",
          category: "Support Needs"
        }
      ],
      growth: [
        {
          text: "My manager is equipped to support the team through rapid growth",
          type: "yesNo",
          category: "Growth Support"
        },
        {
          text: "My manager has adjusted their leadership style to meet new demands",
          type: "yesNo",
          category: "Adaptability"
        },
        {
          text: "What's one way your manager could better support you during this growth phase?",
          type: "openEnded",
          category: "Support Needs"
        }
      ],
      remote: [
        {
          text: "My manager has adapted well to leading in a remote environment",
          type: "yesNo",
          category: "Remote Leadership"
        },
        {
          text: "I feel supported by my manager in our remote setting",
          type: "agreement",
          category: "Remote Support"
        },
        {
          text: "What's one way your manager could better support remote teams?",
          type: "openEnded",
          category: "Remote Management"
        }
      ],
      merger: [
        {
          text: "My manager has provided adequate support during this transition",
          type: "yesNo",
          category: "Transition Support"
        },
        {
          text: "My manager has the information they need to lead effectively post-merger",
          type: "yesNo",
          category: "Manager Resources"
        },
        {
          text: "What's one way your manager could better support you through this merger?",
          type: "openEnded",
          category: "Support Needs"
        }
      ]
    }
  },
  
  compensation: {
    base: [
      {
        text: "I feel fairly compensated for my work",
        type: "agreement",
        category: "Fair Pay",
        recommended: true
      },
      {
        text: "I understand how compensation decisions are made",
        type: "agreement",
        category: "Transparency"
      },
      {
        text: "Our benefits package meets my needs",
        type: "agreement",
        category: "Benefits",
        recommended: true
      }
    ],
    contextual: {
      layoffs: [
        {
          text: "My compensation or benefits have changed as a result of the restructuring",
          type: "yesNo",
          category: "Compensation Changes"
        },
        {
          text: "I am satisfied with the company's approach to compensation post-restructuring",
          type: "agreement",
          category: "Satisfaction"
        },
        {
          text: "What concerns do you have about pay and benefits moving forward?",
          type: "openEnded",
          category: "Compensation Concerns"
        }
      ],
      leadershipChange: [
        {
          text: "There have been changes to compensation or benefits under new leadership",
          type: "yesNo",
          category: "Compensation Changes"
        },
        {
          text: "I am satisfied with leadership's approach to pay and benefits",
          type: "agreement",
          category: "Satisfaction"
        },
        {
          text: "What concerns, if any, do you have about future compensation changes?",
          type: "openEnded",
          category: "Future Concerns"
        }
      ],
      growth: [
        {
          text: "Compensation and benefits have kept pace with the company's growth",
          type: "yesNo",
          category: "Growth Alignment"
        },
        {
          text: "I am satisfied with how the company is handling pay adjustments during growth",
          type: "agreement",
          category: "Satisfaction"
        },
        {
          text: "What concerns do you have about pay and benefits as the company scales?",
          type: "openEnded",
          category: "Scaling Concerns"
        }
      ],
      remote: [
        {
          text: "Remote policies have affected my compensation or benefits",
          type: "yesNo",
          category: "Remote Impact"
        },
        {
          text: "Remote employees receive equal pay and opportunities as in-office employees",
          type: "yesNo",
          category: "Pay Equity"
        },
        {
          text: "What concerns, if any, do you have about compensation in our remote model?",
          type: "openEnded",
          category: "Remote Concerns"
        }
      ],
      merger: [
        {
          text: "There have been changes to compensation or benefits as a result of the merger",
          type: "yesNo",
          category: "Merger Impact"
        },
        {
          text: "I am satisfied with how leadership is handling compensation during this transition",
          type: "agreement",
          category: "Satisfaction"
        },
        {
          text: "What concerns do you have about pay and benefits post-merger?",
          type: "openEnded",
          category: "Future Concerns"
        }
      ]
    }
  },
  
  waysOfWorking: {
    base: [
      {
        text: "Do you feel decisions in the company are made in a clear and structured way?",
        type: "agreement",
        category: "Decision Making",
        recommended: true
      },
      {
        text: "Do you feel cross-functional collaboration is smooth and efficient?",
        type: "agreement",
        category: "Collaboration",
        recommended: true
      },
      {
        text: "How well are decisions communicated once they are made?",
        type: "agreement",
        category: "Communication"
      },
      {
        text: "Do meetings at our company have clear agendas and outcomes?",
        type: "agreement",
        category: "Meetings"
      },
      {
        text: "Do you feel safe raising concerns or blockers at work?",
        type: "agreement",
        category: "Psychological Safety",
        recommended: true
      },
      {
        text: "How clearly are roles and responsibilities defined in large projects?",
        type: "agreement",
        category: "Project Management"
      },
      {
        text: "Are our internal processes built to scale effectively?",
        type: "agreement",
        category: "Process Scaling"
      },
      {
        text: "How well does the company prepare teams for change?",
        type: "agreement",
        category: "Change Management"
      },
      {
        text: "How psychologically safe do you feel when taking risks?",
        type: "agreement",
        category: "Risk Taking"
      },
      {
        text: "How effective is our onboarding process?",
        type: "agreement",
        category: "Onboarding"
      },
      {
        text: "How effective is your manager in setting clear expectations?",
        type: "agreement",
        category: "Management"
      },
      {
        text: "Do you feel connected to the company's mission?",
        type: "agreement",
        category: "Mission Alignment"
      },
      {
        text: "Do you feel recognized for your contributions?",
        type: "agreement",
        category: "Recognition"
      },
      {
        text: "How clearly are unacceptable behaviors defined?",
        type: "agreement",
        category: "Behavioral Standards"
      },
      {
        text: "Are company values lived in everyday work?",
        type: "agreement",
        category: "Values"
      },
      {
        text: "Do teams follow through on commitments?",
        type: "agreement",
        category: "Accountability"
      },
      {
        text: "Do you feel the company invests in your development?",
        type: "agreement",
        category: "Development"
      },
      {
        text: "How easy is it to find relevant internal knowledge?",
        type: "agreement",
        category: "Knowledge Sharing"
      },
      {
        text: "How well do teams align on what is most important?",
        type: "agreement",
        category: "Prioritization"
      },
      {
        text: "Do you feel safe addressing conflicts at work?",
        type: "agreement",
        category: "Conflict Resolution"
      }
    ],
    detailed: [
      // Decision Making
      {
        text: "How often do you feel left out of decisions that impact your work?",
        type: "multiChoice",
        options: ["Always", "Sometimes", "Rarely", "Never"],
        category: "Decision Making"
      },
      {
        text: "What's one improvement that would make decision-making more effective?",
        type: "openEnded",
        category: "Decision Making"
      },
      
      // Collaboration
      {
        text: "What is the biggest blocker when working with other teams?",
        type: "multiChoice",
        options: ["Unclear ownership", "Slow response times", "Misalignment on goals", "Other"],
        category: "Collaboration"
      },
      {
        text: "Have we created enough visibility into what other teams are working on?",
        type: "yesNo",
        category: "Collaboration"
      },
      {
        text: "What's one way we could improve collaboration between teams?",
        type: "openEnded",
        category: "Collaboration"
      },
      
      // Communication
      {
        text: "Do you feel we have a good balance between synchronous and asynchronous communication?",
        type: "yesNo",
        category: "Communication"
      },
      {
        text: "Which async tools do you feel are most effective?",
        type: "multiChoice",
        options: ["Slack", "Notion", "Loom", "Google Docs", "Email", "Other"],
        category: "Communication"
      },
      {
        text: "Where do you experience the most communication overload?",
        type: "multiChoice",
        options: ["Meetings", "Slack", "Email", "Documentation", "Other"],
        category: "Communication"
      },
      {
        text: "What's one way we could make async communication work better?",
        type: "openEnded",
        category: "Communication"
      },
      
      // Meetings
      {
        text: "How often do you attend meetings that feel unnecessary?",
        type: "multiChoice",
        options: ["Always", "Sometimes", "Rarely", "Never"],
        category: "Meetings"
      },
      {
        text: "Would you support a company-wide 'fewer meetings' policy?",
        type: "yesNo",
        category: "Meetings"
      },
      {
        text: "What's one meeting practice we should stop or improve?",
        type: "openEnded",
        category: "Meetings"
      },
      
      // Problem Raising
      {
        text: "How clear is the process for escalating an issue?",
        type: "agreement",
        category: "Problem Solving"
      },
      {
        text: "What's the biggest barrier to speaking up about challenges?",
        type: "multiChoice",
        options: ["Fear of backlash", "Unclear process", "No follow-through", "Other"],
        category: "Problem Solving"
      },
      {
        text: "What's one way we could improve how problems are raised and solved?",
        type: "openEnded",
        category: "Problem Solving"
      },
      
      // Project Management
      {
        text: "Do project timelines and expectations feel realistic?",
        type: "yesNo",
        category: "Project Management"
      },
      {
        text: "How well do teams debrief and learn from completed projects?",
        type: "agreement",
        category: "Project Management"
      },
      {
        text: "What's one way we could improve execution on big projects?",
        type: "openEnded",
        category: "Project Management"
      },
      
      // Process Scaling
      {
        text: "Where do processes slow you down the most?",
        type: "openEnded",
        category: "Process Scaling"
      },
      {
        text: "How easy is it to suggest and implement process improvements?",
        type: "agreement",
        category: "Process Scaling"
      },
      {
        text: "What's one process that needs fixing?",
        type: "openEnded",
        category: "Process Scaling"
      },
      
      // Change Management
      {
        text: "Do you feel involved in major company changes that affect your work?",
        type: "yesNo",
        category: "Change Management"
      },
      {
        text: "What's one thing leadership could do to make change easier to navigate?",
        type: "openEnded",
        category: "Change Management"
      },
      
      // Failure Handling
      {
        text: "How often do we learn from failures and improve?",
        type: "agreement",
        category: "Failure Handling"
      },
      {
        text: "What's one way we could create a healthier attitude toward failure?",
        type: "openEnded",
        category: "Failure Handling"
      },
      
      // Onboarding
      {
        text: "What was missing when you first joined?",
        type: "openEnded",
        category: "Onboarding"
      },
      {
        text: "Do new hires get clear expectations about how we work?",
        type: "yesNo",
        category: "Onboarding"
      },
      
      // Termination
      {
        text: "Do you feel performance issues are addressed fairly before termination?",
        type: "yesNo",
        category: "Termination"
      },
      {
        text: "How well does leadership handle exits in a way that respects culture and morale?",
        type: "agreement",
        category: "Termination"
      },
      {
        text: "What's one way we could improve how we handle terminations?",
        type: "openEnded",
        category: "Termination"
      },
      
      // Management
      {
        text: "Do you get enough feedback from your manager?",
        type: "yesNo",
        category: "Management"
      },
      {
        text: "What's one leadership behavior that should be reinforced company-wide?",
        type: "openEnded",
        category: "Management"
      },
      
      // Mission Alignment
      {
        text: "How often do company priorities shift without clear communication?",
        type: "multiChoice",
        options: ["Always", "Sometimes", "Rarely", "Never"],
        category: "Mission Alignment"
      },
      {
        text: "What's one way we could strengthen alignment?",
        type: "openEnded",
        category: "Mission Alignment"
      },
      
      // Incentives
      {
        text: "Do incentives in the company drive the right behaviors?",
        type: "yesNo",
        category: "Incentives"
      },
      {
        text: "How fair and transparent is our compensation and reward structure?",
        type: "agreement",
        category: "Incentives"
      },
      {
        text: "What's one way we could improve incentives?",
        type: "openEnded",
        category: "Incentives"
      },
      
      // Recognition
      {
        text: "How effective are we at celebrating small and big wins?",
        type: "agreement",
        category: "Recognition"
      },
      {
        text: "What's a new way we could celebrate achievements?",
        type: "openEnded",
        category: "Recognition"
      },
      
      // Behavioral Standards
      {
        text: "Do you feel leadership actively addresses toxic behaviors?",
        type: "yesNo",
        category: "Behavioral Standards"
      },
      {
        text: "What's one behavior you wish the company would address?",
        type: "openEnded",
        category: "Behavioral Standards"
      },
      
      // Values
      {
        text: "How often do you see strong company behaviors recognized?",
        type: "multiChoice",
        options: ["Always", "Sometimes", "Rarely", "Never"],
        category: "Values"
      },
      {
        text: "What's one behavior that should be reinforced more?",
        type: "openEnded",
        category: "Values"
      },
      
      // Accountability
      {
        text: "How comfortable do you feel giving upward feedback?",
        type: "agreement",
        category: "Accountability"
      },
      {
        text: "What's one way we could improve accountability?",
        type: "openEnded",
        category: "Accountability"
      },
      
      // Development
      {
        text: "What's one skill you'd like more support in developing?",
        type: "openEnded",
        category: "Development"
      },
      {
        text: "What's one way we could improve learning and development?",
        type: "openEnded",
        category: "Development"
      },
      
      // Knowledge Sharing
      {
        text: "Where do you struggle most in accessing the right information?",
        type: "openEnded",
        category: "Knowledge Sharing"
      },
      {
        text: "What's one way we could make knowledge sharing more seamless?",
        type: "openEnded",
        category: "Knowledge Sharing"
      },
      
      // Prioritization
      {
        text: "How often do priorities shift without clear reasoning?",
        type: "multiChoice",
        options: ["Always", "Sometimes", "Rarely", "Never"],
        category: "Prioritization"
      },
      {
        text: "What's one thing we could do to improve prioritization?",
        type: "openEnded",
        category: "Prioritization"
      },
      
      // Conflict Resolution
      {
        text: "How effective is the company at resolving conflicts fairly?",
        type: "agreement",
        category: "Conflict Resolution"
      },
      {
        text: "What's one way we could improve how we handle conflict?",
        type: "openEnded",
        category: "Conflict Resolution"
      }
    ],
    contextual: {
      // Add contextual questions if needed
    }
  }
};

export const questionTypes = {
  agreement: {
    type: "5-point",
    scale: ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"],
    icon: "📊"
  },
  rating: {
    type: "10-point",
    scale: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    icon: "⭐"
  },
  openEnded: {
    type: "text",
    icon: "✏️"
  },
  yesNo: {
    type: "binary",
    scale: ["Yes", "No"],
    icon: "✓"
  },
  multiChoice: {
    type: "select",
    icon: "📋"
  }
};

export const bestPractices = [
  {
    title: "Keep surveys focused",
    description: "Limit to 15-25 questions to prevent survey fatigue and increase completion rates."
  },
  {
    title: "Mix question types",
    description: "Include both quantitative (rating scales) and qualitative (open-ended) questions."
  },
  {
    title: "Ensure anonymity",
    description: "Anonymous surveys typically yield more honest feedback, especially on sensitive topics."
  },
  {
    title: "Act on results",
    description: "Share key findings and planned actions with your team to build trust in the feedback process."
  },
  {
    title: "Survey regularly",
    description: "Establish a consistent cadence (quarterly is often ideal) to track changes over time."
  }
];

// Additional contextual question sets for specific scenarios
const rapidGrowthQuestions = {
  leadershipTrust: [
    {
      text: "Leadership is effectively managing the company's rapid growth",
      type: "agreement",
      category: "Leadership Trust"
    },
    {
      text: "Leadership is transparent about the challenges of scaling",
      type: "agreement",
      category: "Communication"
    },
    {
      text: "What's one thing leadership could do better during this phase of growth?",
      type: "openEnded",
      category: "Leadership Improvement"
    }
  ],
  careerGrowth: [
    {
      text: "There are more career opportunities due to the company's growth",
      type: "yesNo",
      category: "Career Opportunities"
    },
    {
      text: "Leadership has provided a clear roadmap for career progression in a fast-growing environment",
      type: "yesNo",
      category: "Career Planning"
    },
    {
      text: "What's one way the company could better support your career development right now?",
      type: "openEnded",
      category: "Development Needs"
    }
  ],
  workLifeBalance: [
    {
      text: "Rapid growth has impacted my workload",
      type: "multiChoice",
      options: ["Increased", "Stayed the same", "Decreased"],
      category: "Workload"
    },
    {
      text: "The company is hiring fast enough to support current workloads",
      type: "yesNo",
      category: "Resource Planning"
    },
    {
      text: "What's one thing leadership could do to improve workload management?",
      type: "openEnded",
      category: "Workload Management"
    }
  ],
  teamDynamics: [
    {
      text: "Rapid growth has affected team collaboration",
      type: "multiChoice",
      options: ["Improved", "Stayed the same", "Worsened"],
      category: "Collaboration"
    },
    {
      text: "I feel well connected to new team members as the company scales",
      type: "yesNo",
      category: "Team Connection"
    },
    {
      text: "What's one challenge your team is facing due to rapid growth?",
      type: "openEnded",
      category: "Team Challenges"
    }
  ],
  companyDirection: [
    {
      text: "I believe the company is scaling in the right way",
      type: "yesNo",
      category: "Scaling Strategy"
    },
    {
      text: "I am confident that leadership has a solid plan for sustainable growth",
      type: "agreement",
      category: "Strategic Planning"
    },
    {
      text: "What's one aspect of our scaling strategy that could be clearer?",
      type: "openEnded",
      category: "Strategic Clarity"
    }
  ],
  dei: [
    {
      text: "DEI is being prioritized as the company scales",
      type: "yesNo",
      category: "DEI Priority"
    },
    {
      text: "Diverse perspectives are being included in hiring and decision-making during this growth phase",
      type: "yesNo",
      category: "Inclusive Practices"
    },
    {
      text: "What's one thing the company could do to maintain DEI in a period of rapid growth?",
      type: "openEnded",
      category: "DEI Improvement"
    }
  ],
  managerEffectiveness: [
    {
      text: "My manager is equipped to support the team through rapid growth",
      type: "yesNo",
      category: "Manager Capability"
    },
    {
      text: "My manager has adjusted their leadership style to meet new demands",
      type: "yesNo",
      category: "Adaptability"
    },
    {
      text: "What's one way your manager could better support you right now?",
      type: "openEnded",
      category: "Support Needs"
    }
  ],
  compensation: [
    {
      text: "Compensation and benefits have kept pace with the company's growth",
      type: "yesNo",
      category: "Compensation Alignment"
    },
    {
      text: "I am satisfied with how the company is handling pay adjustments during growth",
      type: "agreement",
      category: "Satisfaction"
    },
    {
      text: "What concerns do you have about pay and benefits as the company scales?",
      type: "openEnded",
      category: "Compensation Concerns"
    }
  ]
};

const remoteHybridQuestions = {
  leadershipTrust: [
    {
      text: "I am confident in leadership's approach to remote work",
      type: "agreement",
      category: "Leadership Trust"
    },
    {
      text: "Leadership has been clear in communicating remote policies",
      type: "yesNo",
      category: "Communication"
    },
    {
      text: "What's one thing leadership could improve about the transition?",
      type: "openEnded",
      category: "Improvement Areas"
    }
  ],
  careerGrowth: [
    {
      text: "Remote work has impacted my career growth opportunities",
      type: "multiChoice",
      options: ["Positively", "No change", "Negatively"],
      category: "Career Impact"
    },
    {
      text: "Leadership provides sufficient career development resources in a remote environment",
      type: "yesNo",
      category: "Development Resources"
    },
    {
      text: "What's one thing that could improve career growth in a remote setup?",
      type: "openEnded",
      category: "Growth Improvement"
    }
  ],
  workLifeBalance: [
    {
      text: "Remote work has affected my work-life balance",
      type: "multiChoice",
      options: ["Improved", "Stayed the same", "Worsened"],
      category: "Balance"
    },
    {
      text: "I feel pressure to be 'always on' in a remote setting",
      type: "yesNo",
      category: "Boundaries"
    },
    {
      text: "What's one change that could improve your work-life balance?",
      type: "openEnded",
      category: "Balance Improvement"
    }
  ],
  teamDynamics: [
    {
      text: "I feel connected to my team in a remote setup",
      type: "agreement",
      category: "Team Connection"
    },
    {
      text: "I have enough opportunities for informal interactions with colleagues",
      type: "yesNo",
      category: "Social Connection"
    },
    {
      text: "What's one way team collaboration could be improved in a remote model?",
      type: "openEnded",
      category: "Collaboration Improvement"
    }
  ],
  companyDirection: [
    {
      text: "The company is handling the remote transition well",
      type: "yesNo",
      category: "Transition Management"
    },
    {
      text: "Leadership has provided clarity on how remote work aligns with business strategy",
      type: "yesNo",
      category: "Strategic Alignment"
    },
    {
      text: "What concerns, if any, do you have about the transition?",
      type: "openEnded",
      category: "Transition Concerns"
    }
  ],
  dei: [
    {
      text: "Remote policies are equitable for all employees",
      type: "yesNo",
      category: "Equity"
    },
    {
      text: "Remote changes have impacted workplace inclusivity",
      type: "multiChoice",
      options: ["Improved", "Stayed the same", "Worsened"],
      category: "Inclusivity"
    },
    {
      text: "What's one DEI consideration leadership should focus on in a remote model?",
      type: "openEnded",
      category: "DEI Focus"
    }
  ],
  managerEffectiveness: [
    {
      text: "My manager has adapted well to leading in a remote environment",
      type: "yesNo",
      category: "Remote Leadership"
    },
    {
      text: "I feel supported by my manager in a remote setting",
      type: "agreement",
      category: "Manager Support"
    },
    {
      text: "What's one way your manager could better support remote teams?",
      type: "openEnded",
      category: "Support Improvement"
    }
  ],
  compensation: [
    {
      text: "Remote policies have affected my compensation or benefits",
      type: "yesNo",
      category: "Compensation Impact"
    },
    {
      text: "Remote employees receive equal pay and opportunities as in-office employees",
      type: "yesNo",
      category: "Pay Equity"
    },
    {
      text: "What concerns, if any, do you have about compensation in a remote model?",
      type: "openEnded",
      category: "Compensation Concerns"
    }
  ]
};

const mergerAcquisitionQuestions = {
  leadershipTrust: [
    {
      text: "I am confident in leadership's handling of the merger/acquisition",
      type: "agreement",
      category: "Leadership Trust"
    },
    {
      text: "Leadership has been transparent about the impact of this change",
      type: "yesNo",
      category: "Transparency"
    },
    {
      text: "What's one thing leadership could do to build more trust?",
      type: "openEnded",
      category: "Trust Building"
    }
  ],
  careerGrowth: [
    {
      text: "I see new career opportunities arising from this merger/acquisition",
      type: "yesNo",
      category: "Career Opportunities"
    },
    {
      text: "I have received clarity on how this will impact career growth",
      type: "yesNo",
      category: "Career Clarity"
    },
    {
      text: "What's one thing leadership could do to support career development through this transition?",
      type: "openEnded",
      category: "Development Support"
    }
  ],
  workLifeBalance: [
    {
      text: "The merger/acquisition has affected my workload",
      type: "multiChoice",
      options: ["Increased", "Stayed the same", "Decreased"],
      category: "Workload Impact"
    },
    {
      text: "I feel supported in maintaining work-life balance during this transition",
      type: "yesNo",
      category: "Balance Support"
    },
    {
      text: "What's one thing that would help you manage workload better right now?",
      type: "openEnded",
      category: "Workload Management"
    }
  ],
  teamDynamics: [
    {
      text: "Teams from both companies are integrating well",
      type: "agreement",
      category: "Team Integration"
    },
    {
      text: "Collaboration has changed due to the merger",
      type: "multiChoice",
      options: ["Improved", "Stayed the same", "Worsened"],
      category: "Collaboration"
    },
    {
      text: "What's one challenge your team is facing in this transition?",
      type: "openEnded",
      category: "Team Challenges"
    }
  ],
  companyDirection: [
    {
      text: "I feel aligned with the new company direction post-merger",
      type: "yesNo",
      category: "Strategic Alignment"
    },
    {
      text: "Leadership has clearly explained the long-term vision",
      type: "agreement",
      category: "Vision Clarity"
    },
    {
      text: "What's one concern you have about the company's direction?",
      type: "openEnded",
      category: "Direction Concerns"
    }
  ],
  dei: [
    {
      text: "DEI values are being maintained during the merger",
      type: "yesNo",
      category: "DEI Maintenance"
    },
    {
      text: "There have been noticeable changes in DEI efforts",
      type: "multiChoice",
      options: ["Improved", "Stayed the same", "Worsened"],
      category: "DEI Changes"
    },
    {
      text: "What's one thing leadership should prioritize for DEI post-merger?",
      type: "openEnded",
      category: "DEI Priorities"
    }
  ],
  managerEffectiveness: [
    {
      text: "My manager has provided adequate support during this transition",
      type: "yesNo",
      category: "Manager Support"
    },
    {
      text: "My manager has the information they need to lead effectively post-merger",
      type: "yesNo",
      category: "Manager Resources"
    },
    {
      text: "What's one way your manager could better support you?",
      type: "openEnded",
      category: "Support Needs"
    }
  ],
  compensation: [
    {
      text: "There have been changes to compensation or benefits as a result of the merger",
      type: "yesNo",
      category: "Compensation Changes"
    },
    {
      text: "I am satisfied with how leadership is handling compensation during this transition",
      type: "agreement",
      category: "Compensation Satisfaction"
    },
    {
      text: "What concerns do you have about pay and benefits post-merger?",
      type: "openEnded",
      category: "Compensation Concerns"
    }
  ]
};

export function generateQuestions(formData) {
  // Extract selected focus areas and recent events
  const { focusAreas, recentEvents, questionCount } = formData;
  
  // Get base questions from selected focus areas
  let questions = getBaseQuestions(focusAreas);
  
  // Add contextual questions based on recent events
  if (recentEvents && recentEvents.length > 0) {
    questions = addContextualQuestions(questions, formData);
  }
  
  // Prioritize recommended questions
  questions = prioritizeRecommendedQuestions(questions);
  
  // Balance question types
  questions = balanceQuestionTypes(questions, questionCount);
  
  // Ensure we have at least one open-ended question
  questions = ensureOpenEndedQuestions(questions);
  
  // Limit to requested number of questions
  return questions.slice(0, questionCount);
}

function getBaseQuestions(focusAreas) {
  let baseQuestions = [];
  
  focusAreas.forEach(area => {
    if (questionBank[area] && questionBank[area].base) {
      baseQuestions.push(...questionBank[area].base);
    }
  });
  
  return shuffle(baseQuestions);
}

function prioritizeRecommendedQuestions(questions) {
  return questions.sort((a, b) => {
    if (a.recommended && !b.recommended) return -1;
    if (!a.recommended && b.recommended) return 1;
    return 0;
  });
}

function addContextualQuestions(questions, formData) {
  const contextual = [];
  
  // Process standard contextual questions from question bank
  formData.recentEvents.forEach(event => {
    formData.focusAreas.forEach(area => {
      if (questionBank[area]?.contextual?.[event]) {
        contextual.push(...questionBank[area].contextual[event]);
      }
    });
  });
  
  // Add specialized question sets for specific scenarios
  if (formData.recentEvents.includes('growth') || formData.recentEvents.includes('rapidGrowth')) {
    addSpecializedQuestions(contextual, rapidGrowthQuestions, formData.focusAreas);
  }
  
  if (formData.recentEvents.includes('remote') || formData.recentEvents.includes('hybrid')) {
    addSpecializedQuestions(contextual, remoteHybridQuestions, formData.focusAreas);
  }
  
  if (formData.recentEvents.includes('merger') || formData.recentEvents.includes('acquisition')) {
    addSpecializedQuestions(contextual, mergerAcquisitionQuestions, formData.focusAreas);
  }
  
  // For layoffs/restructuring and leadership changes, ensure comprehensive coverage
  if (formData.recentEvents.includes('layoffs') || formData.recentEvents.includes('restructuring')) {
    ensureLayoffRestructuringCoverage(contextual, formData.focusAreas);
  }
  
  if (formData.recentEvents.includes('leadershipChange')) {
    ensureLeadershipChangeCoverage(contextual, formData.focusAreas);
  }
  
  // Add contextual questions to the main question set
  return [...questions, ...shuffle(contextual).slice(0, Math.min(contextual.length, formData.questionCount / 2))];
}

function addSpecializedQuestions(contextual, specializedSet, focusAreas) {
  // Map focus areas to specialized question categories
  const focusToSpecializedMap = {
    'leadership': ['leadershipTrust', 'companyDirection'],
    'careerGrowth': ['careerGrowth'],
    'workLifeBalance': ['workLifeBalance'],
    'teamDynamics': ['teamDynamics'],
    'dei': ['dei'],
    'manager': ['managerEffectiveness'],
    'compensation': ['compensation']
  };
  
  // Add relevant specialized questions based on selected focus areas
  focusAreas.forEach(focus => {
    const specializedCategories = focusToSpecializedMap[focus] || [];
    specializedCategories.forEach(category => {
      if (specializedSet[category]) {
        contextual.push(...specializedSet[category]);
      }
    });
  });
  
  // Always include at least one question from each major category for comprehensive coverage
  const majorCategories = ['leadershipTrust', 'teamDynamics', 'workLifeBalance', 'companyDirection'];
  majorCategories.forEach(category => {
    if (!contextual.some(q => q.category.includes(category.replace(/([A-Z])/g, ' $1').trim()))) {
      if (specializedSet[category] && specializedSet[category].length > 0) {
        contextual.push(specializedSet[category][0]);
      }
    }
  });
}

function ensureLayoffRestructuringCoverage(contextual, focusAreas) {
  // Ensure we have at least one question from each key category for layoffs/restructuring
  const keyCategories = ['Leadership Trust', 'Career Path', 'Workload', 'Team Challenges', 
                         'Strategic Direction', 'Fairness', 'Support', 'Compensation Changes'];
  
  keyCategories.forEach(category => {
    const hasCategory = contextual.some(q => q.category === category);
    if (!hasCategory) {
      // Add a question for this category if missing
      const relevantQuestions = Object.values(questionBank)
        .flatMap(area => 
          area.contextual?.layoffs?.filter(q => q.category === category) || 
          area.contextual?.restructuring?.filter(q => q.category === category) || 
          []
        );
      
      if (relevantQuestions.length > 0) {
        contextual.push(relevantQuestions[0]);
      }
    }
  });
}

function ensureLeadershipChangeCoverage(contextual, focusAreas) {
  // Ensure we have at least one question from each key category for leadership changes
  const keyCategories = ['Leadership Trust', 'Leadership Values', 'Leadership Support', 
                         'Team Dynamics', 'Strategic Direction', 'Leadership Priority', 
                         'Alignment', 'Satisfaction'];
  
  keyCategories.forEach(category => {
    const hasCategory = contextual.some(q => q.category === category);
    if (!hasCategory) {
      // Add a question for this category if missing
      const relevantQuestions = Object.values(questionBank)
        .flatMap(area => 
          area.contextual?.leadershipChange?.filter(q => q.category === category) || 
          []
        );
      
      if (relevantQuestions.length > 0) {
        contextual.push(relevantQuestions[0]);
      }
    }
  });
}

function shuffle(array) {
  return [...array].sort(() => Math.random() - 0.5);
}

function balanceQuestionTypes(questions, maxQuestions) {
  const typeTargets = {
    agreement: 0.5,
    rating: 0.1,
    openEnded: 0.15,
    yesNo: 0.15,
    multiChoice: 0.1
  };

  // First, ensure we have at least one of each type if possible
  const types = Object.keys(typeTargets);
  types.forEach(type => {
    if (!questions.some(q => q.type === type)) {
      // Try to find a question of this type from the question bank
      for (const area of Object.values(questionBank)) {
        const typeQuestion = area.base?.find(q => q.type === type);
        if (typeQuestion) {
          questions.unshift(typeQuestion);
          break;
        }
      }
    }
  });

  // Then sort to balance according to targets
  return questions.sort((a, b) => {
    const aCurrentRatio = questions.filter(q => q.type === a.type).length / questions.length;
    const bCurrentRatio = questions.filter(q => q.type === b.type).length / questions.length;
    const aTarget = typeTargets[a.type] || 0;
    const bTarget = typeTargets[b.type] || 0;
    
    return Math.abs(aCurrentRatio - aTarget) - Math.abs(bCurrentRatio - bTarget);
  });
}

function ensureOpenEndedQuestions(questions) {
  const openEndedCount = questions.filter(q => q.type === 'openEnded').length;
  if (openEndedCount < 1) {
    const generalOpenEnded = {
      text: "What's one thing we could do to improve your experience at work?",
      type: "openEnded",
      category: "General Feedback"
    };
    questions.push(generalOpenEnded);
  }
  return questions;
}