import React, {useState, useEffect} from "react";
import Button from "../cosmetics/Button";
import Container from "./Container";
import LogoCloud from "./LogoCloud";
import HeroScreenshot from "../../images/screenshots/main-dashboard-screenshot.webp"
import VideoModal from "../modals/videoModal";
import { Link } from "../../util/router";
import { useAuth } from "../../util/auth";
import { PlayCircleIcon, ShieldCheckIcon, StarIcon } from '@heroicons/react/24/solid';
import { useHistory } from "react-router-dom";
import HollyAvatar from '../../images/holly_avatar.jpeg';

function useCountAnimation(end, duration = 2000, shouldAnimate) {
  const [count, setCount] = useState(0);
  
  useEffect(() => {
    if (!shouldAnimate) return;
    
    let startTime;
    let animationFrame;
    
    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = Math.min((currentTime - startTime) / duration, 1);
      
      if (progress < 1) {
        setCount(Math.floor(end * progress));
        animationFrame = requestAnimationFrame(animate);
      } else {
        setCount(end);
      }
    };
    
    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration, shouldAnimate]);
  
  return count;
}

function Hero(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const auth = useAuth();
  const videoUrl = "https://www.youtube.com/embed/uwA5HJUz1xY?autoplay=1&vq=highres";
  const history = useHistory();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  
  // Stats counters with consistent animation trigger
  const contributorsCount = useCountAnimation(600, 2000, shouldAnimate);
  const satisfactionCount = useCountAnimation(94, 2000, shouldAnimate);
  const dataPointsCount = useCountAnimation(28, 2000, shouldAnimate);
  const benchmarksCount = useCountAnimation(18, 2000, shouldAnimate);

  useEffect(() => {
    // Small delay before starting animation
    const timer = setTimeout(() => {
      setShouldAnimate(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  const handleTrialClick = () => {
    if (auth.user) {
      // Check if user has completed onboarding
      if (!auth.user.onboardingCompletedAt) {
        history.push('/onboarding');
      } else {
        history.push('/dashboard');
      }
    } else {
      // Not signed in, go to signup page
      history.push('/auth/signup');
    }
  };

  return (
    <Container>
      <div className="relative pt-4 sm:pt-8">
        <div className="py-8 sm:py-16 md:py-24">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mb-8 sm:mb-12 grid grid-cols-4 gap-4 sm:gap-8">
              <div className="text-center p-3 sm:p-4">
                <div className="text-3xl sm:text-4xl font-bold text-indigo-600">
                  {contributorsCount}+
                </div>
                <div className="text-xs sm:text-sm text-gray-600">Scale-ups Contributing</div>
              </div>
              <div className="text-center p-3 sm:p-4">
                <div className="text-3xl sm:text-4xl font-bold text-indigo-600">
                  {satisfactionCount}%
                </div>
                <div className="text-xs sm:text-sm text-gray-600">Would Recommend</div>
              </div>
              <div className="text-center p-3 sm:p-4">
                <div className="text-3xl sm:text-4xl font-bold text-indigo-600">
                  {dataPointsCount}k
                </div>
                <div className="text-xs sm:text-sm text-gray-600">Data Points</div>
              </div>
              <div className="text-center p-3 sm:p-4">
                <div className="text-3xl sm:text-4xl font-bold text-indigo-600">
                  {benchmarksCount}
                </div>
                <div className="text-xs sm:text-sm text-gray-600">Available Benchmarks</div>
              </div>
            </div>

            <div className="mx-auto max-w-3xl text-center">
              <div className="mb-6 sm:mb-8 inline-flex flex-col sm:flex-row items-center gap-2 sm:gap-x-2 rounded-full border border-gray-200 bg-white/50 px-3 py-2 sm:px-4 sm:py-2 backdrop-blur-sm">
                <img
                  className="h-8 w-8 rounded-full ring-2 ring-white"
                  src={HollyAvatar}
                  alt="Holly Leckenby Rye"
                />
                <span className="text-xs sm:text-sm text-gray-600 text-center sm:text-left">
                  <span className="font-medium text-gray-900 block sm:inline">Holly Leckenby Rye</span>
                  {" "}— "Perfect for our People team to become more data-focused"
                </span>
                <div className="flex items-center gap-0.5 mt-1 sm:mt-0">
                  {[...Array(5)].map((_, i) => (
                    <StarIcon 
                      key={i} 
                      className="h-4 w-4 text-yellow-400"
                      aria-hidden="true"
                    />
                  ))}
                </div>
              </div>

              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900">
                Benchmark Your Scale-up's People And Talent Metrics
              </h1>
              
              <p className="mt-4 sm:mt-8 text-base sm:text-lg leading-7 sm:leading-8 text-gray-600 px-4 sm:px-0">
              Get instant access to verified data from 600+ startups across 18 x people and talent metrics. Gain the insights to make smarter decisions and give stakeholders the context they crave. Try it free for 14 days.              </p>
              
              <div className="mt-6 sm:mt-10 flex flex-col items-center">
                <Button 
                  variant="gradient" 
                  onClick={handleTrialClick}
                  className="w-full sm:w-auto px-6 sm:px-8 py-2.5 sm:py-3 text-sm sm:text-base"
                >
                  🎉 Start 14-day free trial
                </Button>
                
                <div className="mt-4 sm:mt-6 flex flex-col sm:flex-row items-center gap-3 sm:gap-x-6">
                  <div className="flex items-center gap-x-2">
                    <ShieldCheckIcon className="h-4 sm:h-5 w-4 sm:w-5 text-green-500" />
                    <span className="text-xs sm:text-sm text-gray-500">No credit card required</span>
                  </div>
                  <div className="hidden sm:block h-4 w-px bg-gray-200"></div>
                  <p className="text-xs sm:text-sm text-gray-500">
                    Used by 600+ scale-ups every month
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-8 sm:mt-16 md:mt-24 flow-root">
              <div 
                className="relative -m-2 rounded-lg sm:rounded-xl bg-gradient-to-b from-indigo-50 to-white p-2 sm:p-4 ring-1 ring-inset ring-gray-900/10 group cursor-pointer 
                  before:absolute before:inset-0 before:-z-10 before:rounded-xl before:bg-gradient-to-b before:from-indigo-500/5 before:to-blue-500/5 before:blur-2xl
                  hover:shadow-[0_0_60px_-15px_rgba(79,70,229,0.3)] transition-shadow duration-300"
                onClick={() => setModalOpen(true)}
              >
                <div className="absolute -top-4 -left-4 -z-10 h-24 w-24 rounded-full bg-gradient-to-r from-indigo-400 to-blue-400 opacity-10 blur-xl"></div>
                <div className="absolute -bottom-4 -right-4 -z-10 h-24 w-24 rounded-full bg-gradient-to-r from-indigo-400 to-blue-400 opacity-10 blur-xl"></div>

                <img
                  src={HeroScreenshot}
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="rounded-md shadow-2xl ring-1 ring-gray-900/10 transition-transform duration-300 group-hover:scale-[1.01]"
                />

                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="relative">
                    <div className="absolute -inset-3 sm:-inset-4 rounded-full bg-indigo-500/20 animate-ping opacity-75 group-hover:opacity-100"></div>
                    <div className="absolute -inset-6 sm:-inset-8 rounded-full bg-indigo-500/10 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                    <PlayCircleIcon className="relative h-12 w-12 sm:h-20 sm:w-20 text-white drop-shadow-lg transform transition-all duration-300 
                      group-hover:scale-110 group-hover:text-indigo-100
                      [filter:drop-shadow(0_0_20px_rgba(79,70,229,0.3))]" />
                  </div>
                </div>

                <div className="absolute bottom-2 sm:bottom-4 left-2 sm:left-4 flex items-center space-x-2 rounded-full bg-black/70 px-3 sm:px-4 py-1.5 sm:py-2 backdrop-blur-sm">
                  <div className="h-1.5 sm:h-2 w-1.5 sm:w-2 rounded-full bg-green-500 animate-pulse"></div>
                  <span className="text-xs sm:text-sm font-medium text-white">Watch demo (9 min)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LogoCloud />
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
      <VideoModal isOpen={modalOpen} onClose={() => setModalOpen(false)} videoUrl={videoUrl} />
    </Container>
  );
}

export default Hero;

