import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toPng } from "html-to-image";
import Logo from '../../images/transparent-logo.png';
import { useRef } from 'react';

export default function DownloadChartModal({ isOpen, onClose, title, message, renderChart, fileName }) {
    const chartRef = useRef(null);

    const htmlToImageConvert = () => {
        toPng(chartRef.current, {
            cacheBust: false,
            backgroundColor: "white",
            scale: 2,
            pixelRatio: 2,
        })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = fileName || "chart.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const renderChartForDownload = () => {
        // Call the renderChart function with showLegendButtons set to false
        // and pass a no-op function for onCalloutToggle
        return renderChart({ showLegendButtons: false, onCalloutToggle: () => {} });
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-screen-lg max-h-screen h-full flex flex-col p-4">
                                <div className="flex justify-between items-start">
                                    <Dialog.Title as="h3" className="text-left font-semibold text-gray-900">
                                        {title}
                                    </Dialog.Title>

                                </div>
                                <div className="p-2" ref={chartRef}>
                                    <div className="p-3 mt-3 bg-indigo-400 rounded-lg" >
                                        <div className="p-2 m-4 bg-white rounded-lg">
                                            <div className="flex justify-end items-center w-full">
                                                <div className="flex items-center space-x-2 mr-2">
                                                    <img src={Logo} alt="Logo" className="w-7 h-7" />
                                                    <span className="text-sm font-bold text-gray-900">peoplemetrics.fyi</span>
                                                </div>
                                            </div>
                                            {renderChartForDownload()}
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <p className="text-sm text-gray-500">{message}</p>
                                </div>
                                <div className="mt-3 sm:mt-3 flex gap-4">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-indigo-600 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={htmlToImageConvert}
                                    >
                                        Download
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    );
}