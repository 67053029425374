import React from 'react';

export default function TestShared() {
  const testSurvey = {
    questions: [
      {
        text: "I trust our leadership team",
        type: "agreement",
        category: "Leadership"
      },
      {
        text: "I understand our company's strategic direction",
        type: "agreement",
        category: "Strategy"
      }
    ],
    metadata: {
      template: "Custom Survey",
      focusAreas: ["leadership", "strategy"]
    }
  };

  const generateTestUrl = () => {
    const encoded = btoa(JSON.stringify(testSurvey));
    const url = `/shared/${encoded}`;
    return url;
  };

  const handleClick = () => {
    const url = generateTestUrl();
    window.location.href = url;
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Test Shared Survey Page</h1>
      <button
        onClick={handleClick}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        View Test Survey
      </button>
      <div className="mt-4">
        <p className="text-sm text-gray-600">Test survey data:</p>
        <pre className="bg-gray-100 p-4 mt-2 rounded overflow-auto">
          {JSON.stringify(testSurvey, null, 2)}
        </pre>
      </div>
    </div>
  );
} 