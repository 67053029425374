import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Section from "../../components/general/Section";
import { motion, AnimatePresence } from "framer-motion";
import { 
  ClipboardDocumentIcon,
  SparklesIcon,
  CheckIcon,
  ExclamationTriangleIcon,
  ArrowRightIcon,
  StarIcon,
  ChatBubbleBottomCenterTextIcon,
  AdjustmentsHorizontalIcon,
  CogIcon
} from '@heroicons/react/24/outline';

// Import the logo directly
import transparentLogo from '../../images/transparent-logo.png';

// Question type configuration to match SurveyGenerator.js
const questionTypeConfig = {
  agreement: { 
    icon: StarIcon,
    color: 'bg-blue-100 text-blue-800',
    label: 'Agreement Scale'
  },
  rating: {
    icon: StarIcon,
    color: 'bg-purple-100 text-purple-800',
    label: 'Rating Scale'
  },
  openEnded: {
    icon: ChatBubbleBottomCenterTextIcon,
    color: 'bg-green-100 text-green-800',
    label: 'Open-Ended'
  },
  yesNo: {
    icon: CheckIcon,
    color: 'bg-amber-100 text-amber-800',
    label: 'Yes/No'
  },
  multiChoice: {
    icon: AdjustmentsHorizontalIcon,
    color: 'bg-rose-100 text-rose-800',
    label: 'Multiple Choice'
  }
};

export default function SharedSurvey() {
  const { id } = useParams();
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [survey, setSurvey] = useState(null);

  useEffect(() => {
    const decodeSurvey = () => {
      try {
        // Add padding if needed
        const base64 = id.replace(/-/g, '+').replace(/_/g, '/');
        const padding = '='.repeat((4 - (base64.length % 4)) % 4);
        const paddedBase64 = base64 + padding;

        // Decode base64 to JSON string
        const jsonString = atob(paddedBase64);
        const surveyData = JSON.parse(jsonString);

        console.log('Decoded survey:', surveyData); // Debug log

        // Validate survey structure
        if (!surveyData.questions || !Array.isArray(surveyData.questions)) {
          throw new Error('Invalid survey format');
        }

        setSurvey(surveyData);
        setError(null);
      } catch (error) {
        console.error('Decoding error:', error, '\nInput ID:', id);
        setError('This survey link appears to be invalid or corrupted. Please try generating a new one.');
      } finally {
        setLoading(false);
      }
    };

    decodeSurvey();
  }, [id]);

  const handleCopy = () => {
    if (!survey?.questions) return;
    const text = survey.questions.map(q => q.text).join('\n\n');
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Fix the icon rendering in the question display
  const renderQuestionIcon = (type) => {
    const config = questionTypeConfig[type] || questionTypeConfig.agreement;
    const IconComponent = config.icon;
    return <IconComponent className="mr-1 h-3 w-3" />;
  };

  if (loading) {
    return (
      <Section bgColor="bg-gray-50" className="min-h-screen">
        <div className="flex items-center justify-center min-h-[60vh]">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            className="w-8 h-8 border-2 border-blue-600 border-t-transparent rounded-full"
          />
        </div>
      </Section>
    );
  }

  if (error) {
    return (
      <Section bgColor="bg-gray-50" className="min-h-screen">
        <div className="max-w-md mx-auto text-center py-16 px-4">
          <ExclamationTriangleIcon className="w-12 h-12 text-yellow-500 mx-auto mb-4" />
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Invalid Survey Link</h1>
          <p className="text-gray-600 mb-8">{error}</p>
          <a
            href="/tools/survey-generator"
            className="inline-flex items-center px-6 py-3 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors"
          >
            Create New Survey
          </a>
        </div>
      </Section>
    );
  }

  return (
    <Section bgColor="bg-gray-50" className="min-h-screen">
      {/* Hero Section with Enhanced Gradient */}
      <div className="relative overflow-hidden">
        {/* Background with Hero.js-inspired gradient */}
        <div className="absolute inset-0 bg-gradient-to-tr from-blue-600 via-indigo-600 to-indigo-700">
          <div 
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        </div>

        <div className="relative max-w-6xl mx-auto px-4 py-12 sm:py-24">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <motion.button
              onClick={handleCopy}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="inline-flex items-center px-4 py-3 sm:px-8 sm:py-4 mb-6 sm:mb-8 rounded-xl bg-white/10 backdrop-blur-sm text-white hover:bg-white/20 transition-colors"
            >
              {copied ? (
                <>
                  <CheckIcon className="w-5 h-5 mr-2" />
                  <span className="font-medium">Copied to Clipboard</span>
                </>
              ) : (
                <>
                  <ClipboardDocumentIcon className="w-5 h-5 mr-2" />
                  <span className="font-medium">Copy All Questions</span>
                </>
              )}
            </motion.button>

            <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold text-white mb-4 sm:mb-6 tracking-tight">
              {survey.metadata?.template || "Survey"} Questions
            </h1>
            <div className="flex flex-wrap justify-center gap-2 sm:gap-3 mb-6 sm:mb-8">
              {survey.metadata?.focusAreas?.map((area, index) => (
                <span
                  key={index}
                  className="px-3 py-1 sm:px-4 sm:py-2 rounded-full bg-white/10 backdrop-blur-sm text-white text-xs sm:text-sm"
                >
                  {area}
                </span>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Questions Section */}
      <div className="max-w-4xl mx-auto px-4 -mt-8 sm:-mt-12 relative z-10 pb-12 sm:pb-24">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-xl ring-1 ring-gray-900/10 overflow-hidden
            before:absolute before:inset-0 before:-z-10 before:rounded-xl before:bg-gradient-to-b before:from-indigo-500/5 before:to-blue-500/5 before:blur-2xl"
        >
          {/* Metadata */}
          <div className="p-4 sm:p-8 border-b border-gray-200 bg-gray-50/50">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div>
                <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider">Template</h3>
                <p className="mt-2 text-lg text-gray-900">{survey.metadata?.template || "Custom Survey"}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider">Focus Areas</h3>
                <div className="mt-2 flex flex-wrap gap-2">
                  {survey.metadata?.focusAreas?.map((area, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                    >
                      {area}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Questions with enhanced design */}
          <div className="divide-y divide-gray-200">
            {survey.questions.map((question, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="p-4 sm:p-8 hover:bg-gray-50/50 transition-colors"
              >
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-2 sm:gap-4">
                  <div className="flex-1">
                    <p className="text-base sm:text-lg text-gray-900 font-medium leading-relaxed">
                      {question.text}
                    </p>
                    <p className="text-xs sm:text-sm text-gray-500 mt-2 flex flex-wrap items-center gap-2">
                      {question.category}
                      {question.recommended && (
                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                          Recommended
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="mt-2 sm:mt-0">
                    {/* Display question type with appropriate styling */}
                    {question.type && (
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${questionTypeConfig[question.type]?.color || 'bg-gray-100 text-gray-600'}`}>
                        {questionTypeConfig[question.type]?.icon && renderQuestionIcon(question.type)}
                        {questionTypeConfig[question.type]?.label || question.type}
                      </span>
                    )}
                  </div>
                </div>

                {/* Display appropriate input based on question type */}
                <div className="mt-4">
                  {question.type === 'agreement' && (
                    <div className="flex flex-col sm:flex-row justify-between items-center text-xs text-gray-500 gap-2">
                      <span>Strongly Disagree</span>
                      <div className="flex space-x-1">
                        {[1, 2, 3, 4, 5].map(num => (
                          <div key={num} className="w-7 h-7 sm:w-8 sm:h-8 rounded-full border border-gray-300 flex items-center justify-center cursor-pointer hover:bg-gray-50">
                            {num}
                          </div>
                        ))}
                      </div>
                      <span>Strongly Agree</span>
                    </div>
                  )}
                  
                  {question.type === 'rating' && (
                    <div className="flex flex-col sm:flex-row justify-between items-center text-xs text-gray-500 gap-2">
                      <span>Not at all likely</span>
                      <div className="flex flex-wrap justify-center gap-1">
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                          <div key={num} className="w-5 h-5 sm:w-6 sm:h-6 rounded-full border border-gray-300 flex items-center justify-center cursor-pointer hover:bg-gray-50 text-xs">
                            {num}
                          </div>
                        ))}
                      </div>
                      <span>Extremely likely</span>
                    </div>
                  )}
                  
                  {question.type === 'openEnded' && (
                    <div className="mt-2">
                      <div className="border border-gray-300 rounded-md px-3 py-2 text-gray-400 bg-gray-50">
                        Type your answer here...
                      </div>
                    </div>
                  )}
                  
                  {question.type === 'yesNo' && (
                    <div className="flex space-x-3 mt-2">
                      <button className="flex-1 border border-gray-300 rounded-md py-2 hover:bg-gray-50">Yes</button>
                      <button className="flex-1 border border-gray-300 rounded-md py-2 hover:bg-gray-50">No</button>
                    </div>
                  )}
                  
                  {question.type === 'multiChoice' && (
                    <div className="space-y-2 mt-2">
                      {(question.options || ['Option 1', 'Option 2', 'Option 3']).map((option, i) => (
                        <div key={i} className="flex items-center">
                          <input
                            type="radio"
                            name={`question-${index}`}
                            className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                          />
                          <label className="ml-2 text-sm text-gray-700">{option}</label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Enhanced CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="mt-8 sm:mt-16"
        >
          <div className="relative rounded-2xl overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-600 opacity-90" />
            <div className="relative px-4 sm:px-8 py-8 sm:py-12 text-center">
              <h2 className="text-2xl sm:text-3xl font-bold text-white mb-3 sm:mb-4">
                Create Your Own Questions
              </h2>
              <p className="text-blue-100 text-base sm:text-lg mb-6 sm:mb-8 max-w-2xl mx-auto">
                Generate research-backed questions to power your next pulse or survey in seconds.
              </p>
              <motion.a
                href="/tools/survey-generator"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="inline-flex items-center px-6 py-3 sm:px-8 sm:py-4 rounded-xl bg-white text-blue-600 hover:bg-blue-50 transition-colors shadow-lg"
              >
                <SparklesIcon className="w-5 h-5 mr-2" />
                <span className="font-medium">Generate Your Questions</span>
                <ArrowRightIcon className="w-5 h-5 ml-2" />
              </motion.a>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Powered by PeopleMetrics Footer - Direct Import Version */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8 }}
        className="mt-8 sm:mt-16 pb-8 text-center"
      >
        <div className="flex items-center justify-center space-x-2 text-gray-500">
          <span>Powered by</span>
          <a 
            href="https://peoplemetrics.fyi" 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors font-medium"
          >
            {/* Use the imported image */}
            <img 
              src={transparentLogo} 
              alt="PeopleMetrics" 
              className="h-5 mr-1" 
            />
            PeopleMetrics
          </a>
        </div>
        <p className="mt-2 text-sm text-gray-400">
          Flex your People team's data muscles with{" "}
          <a 
            href="https://peoplemetrics.fyi" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 transition-colors"
          >
            peoplemetrics.fyi
          </a>
        </p>
      </motion.div>
    </Section>
  );
} 