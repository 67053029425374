import React from 'react'
import { useEffect, useState } from 'react';
import IndexPage from "./index";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "../util/router";
import { Redirect } from "react-router-dom";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import LayoutWithNavAndFooter from "../components/general/LayoutWithNavAndFooter";
import OnboardingPage from "./onboarding";
import EditMetricsPage from "./editMetrics";
import TrendAnalysisPage from "./trendAnalysis";
import LoadingIcon from "../components/cosmetics/LoadingIcon";
import DataStoryPage from "./dataStory";
import SurveyGenerator from "./tools/SurveyGenerator";
import SharedSurvey from "./shared/SharedSurvey";
import TestShared from "./test-shared";

import { useAuth } from "../util/auth";

import "../util/analytics";
import MetricDashboardPage from "./metricDashboard";
import WelcomePage from "./welcome";
import CompCalculator from "./tools/CompCalculator";
import TurnoverCalculator from "./tools/TurnoverCalculator";
import DeiTracker from "./tools/DeiTracker";
import ReviewRoi from "./tools/ReviewRoi";

function PageRoutes() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const isUserPaid = auth.user && ['active', 'trialing'].includes(auth.user.stripeSubscriptionStatus);

  useEffect(() => {
    if (auth.user !== null) {
      setLoading(false);
    }
  }, [auth.user]);

  if (loading) {
    return <LoadingIcon className="w-5" />;
  }

  return (
    <Router>
      <LayoutWithNavAndFooter>
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/pricing" component={PricingPage} />
          <Route exact path="/welcome" component={WelcomePage} />
          <Route exact path="/tools/survey-generator" component={SurveyGenerator} />
          <Route exact path="/tools/compensation-calculator" component={CompCalculator} />
          <Route exact path="/tools/turnover-cost" component={TurnoverCalculator} />
          <Route exact path="/tools/dei-tracker" component={DeiTracker} />
          <Route exact path="/tools/review-roi" component={ReviewRoi} />
          <Route 
            path="/shared/:id" 
            render={props => <SharedSurvey {...props} />} 
          />
          <Route exact path="/test-shared" component={TestShared} />
          <ProtectedRoute exact path="/dashboard" component={DashboardPage} isPaid={isUserPaid} />
          <ProtectedRoute path="/dashboard/metrics/:metricName" component={MetricDashboardPage} isPaid={isUserPaid} />
          <ProtectedRoute exact path="/edit-metrics" component={EditMetricsPage} isPaid={isUserPaid} />
          <ProtectedRoute exact path="/trend-analysis" component={TrendAnalysisPage} isPaid={isUserPaid} />
          <ProtectedRoute exact path="/data-story" component={DataStoryPage} isPaid={isUserPaid} />
          <ProtectedRoute exact path="/onboarding" component={OnboardingPage} isPaid={isUserPaid} />
          <Route exact path="/auth/:type" component={AuthPage} />
          <Route exact path="/settings/:section" component={SettingsPage} />
          <Route exact path="/legal/:section" component={LegalPage} />
          <Route exact path="/purchase/:plan" component={PurchasePage} />
          <Route component={NotFoundPage} />
        </Switch>
      </LayoutWithNavAndFooter>
    </Router>
  );
}

// HOC to protect routes that require payment
const ProtectedRoute = ({ component: Component, isPaid, ...rest }) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        // If not authenticated, wait
        if (!auth.user) {
          return <LoadingIcon className="w-5" />;
        }

        // Always allow access to onboarding
        if (rest.path === "/onboarding") {
          return <Component {...props} />;
        }

        // If they haven't completed onboarding, redirect there
        if (!auth.user.onboardingCompletedAt) {
          return <Redirect to="/onboarding" />;
        }

        // If they're an active subscriber or in trial, allow access
        const isActiveSubscriber = ['active', 'trialing'].includes(auth.user?.stripeSubscriptionStatus);
        if (isActiveSubscriber) {
          return <Component {...props} />;
        }

        // Check trial period
        const now = new Date();
        const trialEnd = auth.user?.trial_end ? new Date(auth.user.trial_end) : null;
        const isInTrialPeriod = trialEnd && now < trialEnd;

        if (isInTrialPeriod) {
          return <Component {...props} />;
        }

        // If we get here, redirect to pricing
        return <Redirect to="/pricing" />;
      }}
    />
  );
};

export default PageRoutes